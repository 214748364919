import DesktopFooter from '../Footer/DesktopFooter';
import MobileFooter from '../Footer/MobileFooter';

import style from './index.module.scss';

const Footer = (props) => {
  const { theme } = props;

  return (
    <div className={style.section}>
      <DesktopFooter theme={theme} />
      <MobileFooter theme={theme} />
    </div>
  );
};

export default Footer;

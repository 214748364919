import { Link, useLocation } from 'react-router-dom';
import style from './index.module.scss';

import officeList from 'data/office-list';
import SocialIcon from 'components/Other/SocialIcon';

import facebookDarkLogo from 'assets/facebook-dark.svg';
import instagramDarkLogo from 'assets/instagram-dark.svg';
import snapchatDarkLogo from 'assets/snapchat-dark.svg';
import googleDarkLogo from 'assets/google-dark.svg';
// import linkedinDarkLogo from 'assets/linkedin-dark.svg';

import facebookLightLogo from 'assets/facebook-light.svg';
import instagramLightLogo from 'assets/instagram-light.svg';
import snapchatLightLogo from 'assets/snapchat-light.svg';
import googleLightLogo from 'assets/google-light.svg';
// import linkedinLightLogo from 'assets/linkedin-light.svg';

import footerLogo from 'assets/adylic-brandmark.png';

import { HashLink } from 'react-router-hash-link';

const DesktopFooter = (props) => {
  const { theme } = props;
  const location = useLocation();

  const hashScroll = (el) => {
    let delay = location.pathname.includes('/about') ? 0 : 500;
    setTimeout(() => {
      const heightOfHeader = document.querySelector('.header').clientHeight;
      const elYCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elYCoordinate - heightOfHeader });
    }, delay);
  };

  const officeLinks = officeList.map((office) => {
    const { urlName, textName } = office;
    return (
      <HashLink
        key={urlName}
        className={style.link}
        to={`/about/${urlName}#location`}
        scroll={hashScroll}
      >
        {textName}
      </HashLink>
    );
  });

  let moduleTheme = null;

  if (style.dark.includes(theme)) {
    moduleTheme = style.dark;
  }

  return (
    <div className={`${style.footer} ${moduleTheme}`}>
      <div className={style['sitemap-container']}>
        <Link className={style.link} to="/">
          Home
        </Link>
        <Link className={style.link} to="/how-it-works">
          How it Works
        </Link>
        <Link className={style.link} to="/about">
          About
        </Link>
        <div>
          <Link className={style.link} to="/legal">
            Anti Slavery Policy
          </Link>
        </div>
      </div>
      <div className={style['offices-container']}>
        <div className={style['column-title']}>Offices</div>
        {officeLinks}
      </div>
      <div className={style['contacts-container']}>
        <div className={style['column-title']}>Get in touch</div>

        <div className={style['contacts-inner']}>
          <div className={style['contact-subtitle']}>Enquiries</div>
          <a href="mailto:hello@adylic.com">hello@adylic.com</a>
        </div>

        <div className={style['contacts-inner']}>
          <div className={style['contact-subtitle']}>Jobs</div>
          <a href="mailto:jobs@adylic.com">jobs@adylic.com</a>
        </div>
        {/* <div className={style["linkedin-container"]}>
          <a href="https://www.linkedin.com/company/adylic/">
            <SocialIcon image={theme === "light" ? linkedinDarkLogo : linkedinLightLogo} />
          </a>
        </div> */}
      </div>

      <div className={style['marketing-partners']}>
        <div className={style['marketing-title']}>Marketing partners with</div>
        <div className={style['marketing-icons']}>
          <SocialIcon
            className={style['social-icon']}
            image={theme === 'light' ? facebookLightLogo : facebookDarkLogo}
          />
          <SocialIcon
            className={style['social-icon']}
            image={theme === 'light' ? instagramLightLogo : instagramDarkLogo}
          />
          <SocialIcon
            className={style['social-icon']}
            image={theme === 'light' ? snapchatLightLogo : snapchatDarkLogo}
          />
          <SocialIcon
            className={style['social-icon']}
            image={theme === 'light' ? googleLightLogo : googleDarkLogo}
          />
        </div>
      </div>

      <div className={style['footer-logo']}>
        <img src={footerLogo} alt="Adylic brandmark" />
      </div>      
    </div>
  );
};

export default DesktopFooter;

import { useEffect, useRef } from 'react';

// components
import Arrow from 'components/Other/Arrow';

// styles
import style from './index.module.scss';

// packages
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

const data = [
  [
    {
      title: 'API INTEGRATION'
    },
    {
      title: 'INTEREST & INTENT SIGNALS'
    },
    {
      title: 'PURCHASE & SERVICE'
    },
    {
      title: 'PRICING INTEGRATION'
    },
    {
      title: 'GEO-TARGETING'
    }
  ],
  [
    {
      title: 'INCREASE PERFORMANCE'
    },
    {
      title: 'REDUCE PRODUCTION COSTS'
    },
    {
      title: 'INCREASE SPEED TO MARKET'
    },
    {
      title: 'LEARN CREATIVE INSIGHTS'
    },
    {
      title: 'PERSONALIZE CONTENT'
    }
  ],
  [
    {
      title: 'CREATIVE TEST DESIGN'
    },
    {
      title: 'MULTIVARIABLE SPLIT TESTS'
    },
    {
      title: 'MULTIVARIANT TESTS'
    },
    {
      title: 'AI POWERED OPTIMIZATION'
    },
    {
      title: 'IN-HOUSE EXPERTISE'
    }
  ]
];

const StrategyThinking = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null),
    boxContainerRef = useRef(null);

  let columns = [];

  // timeline, null before init
  let tl = null;

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { duration: 1, ease: Power3.ease },
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: containerRef.current,
        start: 'top 75%',
        end: 'bottom 50%',
        markers: false,
        scrub: 2,
        once: true
      }
    });

    tl.to(titleRef.current, { autoAlpha: 1 }, 0);
    tl.to(textRef.current, { autoAlpha: 1 }, 0.1);
    tl.to(
      boxContainerRef.current,
      {
        autoAlpha: 1,
        onStart: () => {
          animatedBoxes();
        }
      },
      0.5
    );
  };

  const animatedBoxes = () => {
    // gsap.utils.toArray("." + style.column).forEach((column) => animate(column, gsap.utils.random(0, 3)));
    gsap.utils.toArray('.' + style.column).forEach((column) => animate(column));

    // function animate(column, delay = 0) {
    function animate(column) {
      const children = column.children;
      const len = children.length;

      const card1 = children[len - 1]; // top card
      const card2 = children[len - 2];
      const card3 = children[len - 3];
      const card4 = children[len - 4];
      const card5 = children[len - 5];

      gsap.set(card1, { autoAlpha: '1' });
      gsap.set(card2, { autoAlpha: '0.75' });
      gsap.set(card3, { autoAlpha: '0.5' });
      gsap.set(card4, { autoAlpha: '0' });
      gsap.set(card5, { autoAlpha: '0' });

      gsap.set([...children].reverse(), {
        y: (i) => i * 10
      });

      gsap
        .timeline({
          // delay,
          onComplete() {
            column.prepend(card1);
            setTimeout(() => {
              animate(column);
            }, 3000);
          }
        })
        .to(children, { duration: 1, y: '-=10' }, 0)
        // .to([card2, card3, card4], { duration: 1, autoAlpha: "+=0.25", y: "-=10" }, 0)	//wanted change in opacity to cards
        // .to([card2, card3, card4], { duration: 1, autoAlpha: "+=0.15", y: "-=10" }, 0)
        .to([card2, card3, card4], { duration: 1, autoAlpha: '+=0.2', y: '-=10' }, 0) //wanted more opaque - finding point between original & update
        .to(card1, { autoAlpha: '0', y: '-=10', duration: 1 }, 0);
    }
  };

  // start animation

  useEffect(() => {
    setTimeout(() => {
      const scrollTriggerId = generateId();
      // console.log(`HOW IT WORKS > STRATEGYTHINKING ID: ${scrollTriggerId}`);
      startGsapAnimation(scrollTriggerId, animate);
      return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
    }, 750);
  }, []);

  const boxColumns = data.map((column, i) => {
    const boxes = column.map((box, i) => (
      <div className={style.box} key={i}>
        {box.title}
      </div>
    ));
    return (
      <div className={style.column} key={i} ref={(el) => columns.push(el)}>
        {boxes}
      </div>
    );
  });

  return (
    <div className={style.section} ref={containerRef}>
      <div className={style.container}>
        <div className={style.top}>
          <div className={style['title-container']}>
            <div className={style.title} ref={titleRef}>
              DCO strategy & thinking
            </div>
            <div className={style.text} ref={textRef}>
              Our media integration ensures we are best placed to understand audience data signals,
              campaign objectives and optimization opportunities. Building on this foundation, we
              place strategy & thinking at our core to help enable you to best utilise the varied
              possibilities of dynamic creative.
            </div>
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style['box-container']} ref={boxContainerRef}>
            {boxColumns}
          </div>
        </div>
        <Arrow colour="#FFF" direction="down" classProps={style.arrow} />
      </div>
    </div>
  );
};

export default StrategyThinking;

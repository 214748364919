import { useEffect, useRef } from 'react';
import Media from 'react-media';

// components
// import Arrow from 'components/Other/Arrow';
import HeroLogo from './HeroLogo/HeroLogo';

// styles
import style from './index.module.scss';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

// packages
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

const Hero = () => {
  // register gsap plug in
  gsap.registerPlugin(ScrollTrigger);

  // refs for elements/animation
  const containerRef = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null),
    logoContainerRef = useRef(null);

  // timeline, null before init
  let tl = null;

  // animation
  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { duration: 1, ease: Power3.ease },
      scrollTrigger: {
        id: scrollTriggerId,
        start: 'top center'
        // onUpdate: (self) => {
        // 	  console.log(`progress ${scrollTriggerId}:, progress: ${self.progress}, start: ${self.start}`);
        // 	}
      }
    });

    tl.to(logoContainerRef.current, { autoAlpha: 1 }, 0.5);
    tl.to(titleRef.current.children, { autoAlpha: 1, y: 0, stagger: 0.25 }, 1);
    tl.to(textRef.current.children, { autoAlpha: 1, y: 0, stagger: 0.25 }, '-=0.5');
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`HOME > HERO ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  return (
    <div className={style.section} ref={containerRef}>
      <div className={style.container}>
        <div className={style.left}>
          <div className={style['logo-container']} ref={logoContainerRef}>
            <Media query="(min-width: 1200px)" render={() => <HeroLogo />} />
            <Media
              query="(max-width: 1200px)"
              render={() => (
                <img
                  className={style['mobile-logo']}
                  src={require('assets/adylic-stacked-logo.svg').default}
                />
              )}
            />
          </div>
        </div>
        <div className={style.right}>
          <div className={style['title-container']}>
            <div className={style.title} ref={titleRef}>
              <div className={style['title-line']}>Unlock</div>
              <div className={style['title-line']}>the Science</div>
              <div className={style['title-line']}>of Creativity</div>
            </div>
            <div className={style.text} ref={textRef}>
              <div className={style['text-line']}>Pioneering DCO solutions that power scale</div>
              <div className={style['text-line']}>and performance across paid digital media</div>
            </div>
          </div>
        </div>
        {/* <Arrow colour="#063a53" direction="down" className={style.arrow} /> */}
      </div>
    </div>
  );
};

export default Hero;

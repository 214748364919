const SocialIcon = (props) => {
  const { image } = props;
  return (
    <div className={props.className}>
      <img src={image} />
    </div>
  );
};

export default SocialIcon;

import { forwardRef } from 'react';
import './index.scss';

const JobsItem = (props, ref) => {
  const { image, title } = props;
  return (
    <div className="job-item" ref={ref}>
      <img className="job-image" src={image} />
      <div className="job-title">{title}</div>
    </div>
  );
};

export default forwardRef(JobsItem);

import { useEffect, useRef } from 'react';

// styles
import style from './index.module.scss';

// packages
import ParticlesContainer from 'components/Other/ParticlesContainer';
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

// components
// import Arrow from 'components/Other/Arrow';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

const Dco = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null),
    plusRef = useRef(null),
    dynamicRef = useRef(null),
    optRef = useRef(null);

  // timeline, null before init
  let tl = null;

  // animation
  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { duration: 1, ease: Power3.ease },
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: dynamicRef.current,
        // trigger: containerRef.current,
        start: 'top 100%',
        //start: "top center"
        end: 'bottom 50%',
        scrub: 2,
        once: true
      }
    });

    tl.fromTo(plusRef.current, { autoAlpha: 0, scale: 0 }, { autoAlpha: 1, scale: 1 });
    tl.fromTo(dynamicRef.current, { autoAlpha: 0, x: -50 }, { autoAlpha: 1, x: 0 });
    tl.fromTo(optRef.current, { autoAlpha: 0, x: 50 }, { autoAlpha: 1, x: 0 });
  };

  useEffect(() => {
    setTimeout(() => {
      const scrollTriggerId = generateId();
      // console.log(`HOME > DCO: ${scrollTriggerId}`);
      startGsapAnimation(scrollTriggerId, animate);
      return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
    }, 750);
  }, []);

  return (
    <div className={style.section} ref={containerRef}>
      <ParticlesContainer className={style['particles-container']} />
      <div className={style.container}>
        <div className={style.single}>
          <div className={style['dynamic-creative']} ref={dynamicRef}>
            <div className={style['title-container']}>
              <div className={style.title}>Dynamic Creative</div>
              <div className={style.text}>
                The ability to generate in real time
                <br /> and on a per impression basis,
                <br /> personalized ads using data
              </div>
            </div>
          </div>
          <div className={style.plus} ref={plusRef}>
            +
          </div>
          <div className={style.optimization} ref={optRef}>
            <div className={style['title-container']}>
              <div className={style.title}>Optimization</div>
              <div className={style.text}>
                Iterative creative testing,
                <br /> optimized to a brand’s KPIs
              </div>
            </div>
          </div>
        </div>
        {/* <Arrow colour="#063a53" direction="down" className={style.arrow} /> */}
      </div>
    </div>
  );
};

export default Dco;

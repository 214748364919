import { useEffect, useRef } from 'react';
import './index.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CaseStudy from 'components/CaseStudies/CaseStudy';
// import ScrollToTop from "../../helpers/ScrollToTop";
import gsap, { ScrollTrigger } from 'gsap/all';

import startGsapAnimation from '../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../helpers/generateId';

//VIDEOS
import renaultVideo from 'assets/case_study_renault.mp4';
import mcDonaldsVideo from 'assets/case_study_mcdonalds.mp4';
import quakerVideo from 'assets/case_study_quaker.mp4';

const caseStudiesData = [
  {
    title: 'Renault',
    caseStudyId: 'renault',
    smallDescription: 'Delivering production efficiencies through globally-scaled DCO',
    mainDescription:
      'Renault required a robust DCO framework that balanced the needs of global brand consistency with the flexibility of 30+ markets. Our suite of innovative templates, built in Orca, empower local markets to easily personalize creative, moving their customers through the purchase funnel in a consistent, controlled and effective way.',
    image: null,
    video: {video: renaultVideo, poster: require('../../assets/case_study_renault_cover.jpg').default},
    stats: [
      { name: 'Cost per lead', value: 49, units: '%', isMinus: true },
      { name: 'No. of variants', value: 15, units: 'K', isMinus: false },
      { name: 'Engagement', value: 66, units: 'X', isMinus: false }
    ],
    isReversed: false
  },
  {
    title: 'Delta',
    caseStudyId: 'delta',
    smallDescription: 'Real time automation to improve creative relevancy',
    mainDescription:
      'Delta challenged us to deliver the right route and right price to a user, based on their location and demographic make up. With prices changing every 15 minutes, this was no easy task and a bespoke price feed was built to integrate into Facebook Ads Manager, whilst also testing different content to understand creatively what engages users.',
    image: require('../../assets/case-study2.jpg').default,
    video: null,
    stats: [
      { name: 'Cost per Conversion', value: 16, units: '%', isMinus: true },
      { name: 'Click-Through Rate', value: 21, units: '%', isMinus: false },
      { name: 'Engagement', value: 36, units: 'X', isMinus: false }
    ],
    isReversed: true
  },
  {
    title: 'Burts Bees',
    caseStudyId: 'burts-bees',
    smallDescription: 'Leveraging skin need state data to deliver personalized creative',
    mainDescription:
      'Burt’s Bees wanted to understand how to maximize receptivity of their new line of skin care products. We set up numerous layers of creative testing within video and display based on demographics, affinities, precise skin need states and climate triggers to deduce how to most effectively deliver the right product mix and drive awareness of key product proof-points that would resonate for each of our target sub-segments.',
    image: require('../../assets/case-study3.jpg').default,
    video: null,
    stats: [
      { name: 'DCO creative assets', value: 200, units: '+', isMinus: false },
      { name: 'Brand awareness lift', value: (3.0).toFixed(1), units: '%', isMinus: false }
    ],
    isReversed: false
  },
  {
    title: "McDonald's",
    caseStudyId: 'mcdonalds',
    smallDescription: 'Using data-driven creative to drive footfall',
    mainDescription:
      "McDonald's needed real time agility in driving customers to restaurants… So, we built a dynamic template that enabled live pricing and product imagery to automatically populate depending on the daily offer. By utilizing geo-targeting, we added an extra layer of personalization to drive incremental store visits and further increase ROI.",
    image: null,
    video: {video: mcDonaldsVideo, poster: require('../../assets/case_study_mcdonalds_cover.jpg').default},
    stats: [
      { name: 'Store Uplift', value: 182, units: '%', isMinus: false },
      { name: 'Store Visits', value: 7.9, units: 'M', isMinus: false },
      { name: 'Product Combinations', value: 300, units: '+', isMinus: false }
    ],
    isReversed: true
  },
  {
    title: 'Quaker',
    caseStudyId: 'quaker',
    smallDescription: 'Driving brand lift through dynamic video',
    mainDescription:
      'We loved working with BBDO on the Quaker brand relaunch as every aspect of the campaign would have an element of personalization. Three separate video shoots were produced, creating a unique set of assets that corresponded to core demographics. An extensive set of personalized copy was created to not only appeal to user groups further, but to implement a set of creative tests against each user to optimize on results. Partnering with Google we demonstrated significant brand uplift versus non-personalized variants and a significant ROI for the campaign.',
    image: null,
    video: {video: quakerVideo, poster: require('../../assets/case_study_quaker_cover.jpg').default},
    stats: [
      { name: 'Cost per Conversion', value: 50, units: '%', isMinus: true },
      { name: 'Click-Through Rate', value: 17, units: '%', isMinus: false },
      { name: 'Engagement', value: 3.4, units: 'X', isMinus: false }
    ],
    isReversed: false
  },
  {
    title: 'Audible',
    caseStudyId: 'audible',
    smallDescription: 'Creating production efficiencies DCO',
    mainDescription:
      'Audible challenged us to deliver agility and innovative thinking when promoting their extensive audiobook catalogue… So, we ingested a complex product feed into bespoke video and display templates, allowing us to scale personalized content to their vast array of audiences, providing a consistent and relevant brand experience across multiple digital touchpoints.',
    image: require('../../assets/case-study6.jpg').default,
    video: null,
    stats: [
      { name: 'No. of creatives supported', value: 800, units: '+', isMinus: false },
      { name: 'DCO Cost-Saving', value: 63, units: '%', isMinus: false }
    ],
    isReversed: true
  },
  {
    title: 'HSBC',
    caseStudyId: 'hsbc',
    smallDescription: 'Driving performance uplift through iterative testing',
    mainDescription:
      'HSBC wanted to improve their display performance. With multiple audiences/motivations at different stages of the purchase funnel, we set about testing extensive combinations (over 1,000) of imagery, copy and CTA. A structured Learning Agenda and rigorous data-analysis enabled optimisations that delivered impressive performance uplifts.',
    image: require('../../assets/case-study8.jpg').default,
    video: null,
    stats: [
      { name: 'Click-Through Rate', value: 257, units: '%', isMinus: false },
      { name: 'Conversion Rate', value: 122, units: '%', isMinus: false }
    ],
    isReversed: true
  }
];

const data = [
  {
    bg: require('assets/backgrounds/dark/bg-dark1.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark1.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark2.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark2.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark3.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark3.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark4.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark4.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark5.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark5.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark6.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark6.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark7.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark7.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark1.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark1.jpg').default
  }
];

const CaseStudies = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null);

  let tl = null,
    bgs = [];

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: containerRef.current,
        scrub: true,
        markers: false,
        start: 'top 100%',
        end: 'bottom 100%'
        // onUpdate: (self) => {
        //   console.log(`progress ${scrollTriggerId}:, progress: ${self.progress}, start: ${self.start}`);
        // }
      }
    });

    tl.fromTo(bgs[0], { autoAlpha: '0.75' }, { autoAlpha: '1' });
    tl.fromTo(bgs[1], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[2], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[3], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[4], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[5], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[6], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[7], { autoAlpha: '0' }, { autoAlpha: '1' });
    // tl.fromTo(bgs[8], { autoAlpha: "0" }, { autoAlpha: "1" }); throwing error
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`ROUTES > CASESTUDIES ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  return (
    <>
      <div className="route case-studies" ref={containerRef}>
        <div className="background-container">
          {data.map((bg, i) => (
            <img
              key={i}
              src={bg.bg}
              srcSet={`${bg.bg_x2}, 2x`}
              ref={(el) => {
                bgs.push(el);
              }}
            />
          ))}
        </div>
        <Header theme="dark" />
        {caseStudiesData.map((client, index) => (
          <CaseStudy
            key={client.title}
            {...client}
            isFirstChild={index === 0}
            isPenultimateChild={index === caseStudiesData.length - 2}
            isLastChild={index === caseStudiesData.length - 1 ? true : false}
          />
        ))}
        <Footer theme="dark" />
      </div>
    </>
  );
};

export default CaseStudies;

/* eslint-disable react/jsx-key */
import { useEffect, useRef } from 'react';

// components

// packages
import gsap, { ScrollTrigger } from 'gsap/all';

// styles
import style from './index.module.scss';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

//MAKE INTO SEPARATE FILE
const pointsData = [
  {
    title: 'Iterative A/B split tests',
    text: [
      'learning agendas outlining A/B tests',
      <br />,
      'optimized iteratively based on insights'
    ],
    icon: require('assets/icon1.svg').default
  },
  {
    title: 'Creative tagging',
    text: [
      'to review and learn wider creative trends. All tags are',
      <br />,
      'tailored to a brand’s specific assets and industry '
    ],
    icon: require('assets/icon2.svg').default
  },
  {
    title: 'Auto-optimization',
    text: [
      'on a per-impression basis through optimized',
      <br />,
      'rotation aligned to media optimizations & KPIs'
    ],
    icon: require('assets/icon3.svg').default
  },
  {
    title: 'Creative element level reporting',
    text: [
      'enriching variant level ad-server data',
      <br />,
      'with creative tags & in-platform content'
    ],
    icon: require('assets/icon4.svg').default
  },
  {
    title: 'Data analysts',
    text: [
      'to help you best understand/utilize insights and ensure',
      <br />,
      'all tests/results are valid and statically significant'
    ],
    icon: require('assets/icon5.svg').default
  }
];

const OptInsight = () => {
  // register plugin
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null);

  let bulletList = [];

  let tl = null;

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { duration: 0.5 },
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: containerRef.current,
        start: 'top 100%',
        end: 'bottom 100%',
        markers: false,
        scrub: 2,
        once: true
      }
    });

    tl.to(titleRef.current, { autoAlpha: 1 }, 0);
    tl.to(textRef.current, { autoAlpha: 1 }, 0.5);

    bulletList.forEach((bullet) => {
      tl.fromTo(
        bullet.childNodes[0],
        { autoAlpha: 0, rotate: 0, scale: 0 },
        { autoAlpha: 1, rotate: 360, scale: 1 },
        '-=0.25'
      );
      tl.fromTo(bullet.childNodes[1], { autoAlpha: 0 }, { autoAlpha: 1 }, '-=0.25');
    });
  };

  useEffect(() => {
    setTimeout(() => {
      const scrollTriggerId = generateId();
      // console.log(`HOW IT WORKS > OPTINSIGHT ID: ${scrollTriggerId}`);
      startGsapAnimation(scrollTriggerId, animate);
      return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
    }, 750);
  }, []);

  //MAKE INTO COMPONENT
  const createBullets = () => {
    return pointsData.map((point) => (
      <div
        className={style['bullet']}
        key={point.title}
        ref={(el) => (bulletList.length < pointsData.length ? bulletList.push(el) : null)}
      >
        <div className={style['bullet-image-container']}>
          <img className={style['bullet-image']} src={point.icon} />
        </div>
        <div className={style['bullet-title-container']}>
          <div className={style['bullet-title']}>{point.title}</div>
          <div className={style['bullet-text']}>
            {point.text.map((el, index) => (
              <span key={index}>{el}</span>
            ))}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className={style.section} ref={containerRef}>
      <div className={style.container}>
        <div className={style.left}>
          <div className={style['title-container']}>
            <div className={style.title} ref={titleRef}>
              Optimization
              <br />& insight
            </div>
            <div className={style.text} ref={textRef}>
              Our team of Creative Strategists and Data Analysts help you best utilize DCO in order
              to understand what is working best, why, and optimize accordingly.
            </div>
          </div>
        </div>
        <div className={style.right}>
          <div className={style['bullet-container']}>{createBullets()}</div>
        </div>
      </div>
    </div>
  );
};

export default OptInsight;

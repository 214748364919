import { forwardRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import fullScreenIcon from '../../../assets/fullscreen-icon.svg';
import playIcon from '../../../assets/play-icon.svg';
import pauseIcon from '../../../assets/pause-icon.svg';

// import mutedSoundOffIcon from '../../../assets/muted-sound-off.svg';
// import mutedSoundOnIcon from '../../../assets/muted-sound-on.svg';

const Container = styled.div`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  will-change: auto;
  & > video,
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 10px;
`;

const VideoControls = styled.button`
  width: 30px;
  height: 30px;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  background-color: transparent;
  border: none;
  outline: none;
`;

const FullScreen = styled(VideoControls)`
  background-image: url(${fullScreenIcon});
`;
const Play = styled(VideoControls)`
  background-image: url(${playIcon});
`;
const Pause = styled(VideoControls)`
  background-image: url(${pauseIcon});
`;
// const MutedSoundOff = styled(VideoControls)`
//   background-image: url(${mutedSoundOffIcon});
// `;
// const MutedSoundOn = styled(VideoControls)`
//   background-image: url(${mutedSoundOnIcon});
// `;

const VideoImageContainer = (props, ref) => {
  // const [isMuted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState('loading');

  const { isVideo } = props;

  const fullscreenHandler = () => {
    console.log(ref.current.childNodes[0]);
    ref.current.childNodes[0].webkitEnterFullscreen();
    // ref.current.childNodes[0].requestFullscreen();
    // ref.current.childNodes[0].enterFullscreen();
  };

  const playPauseHandler = () => {
    ref.current.childNodes[0].paused
      ? ref.current.childNodes[0].play()
      : ref.current.childNodes[0].pause();
  };

  // const mutedHandler = () => {
  // 	if (!isMuted) {
  // 		ref.current.childNodes[0].muted = true;
  // 		setMuted(true);
  // 	} else {
  // 		ref.current.childNodes[0].muted = false;
  // 		setMuted(false);
  // 	}
  // };

  const videoListener = () => {
    if (ref.current) {
      ref.current.childNodes[0].addEventListener('play', () => {
        setIsPlaying(true);
      });
      ref.current.childNodes[0].addEventListener('pause', () => {
        setIsPlaying(false);
      });
    }
  };

  useEffect(() => {
    videoListener();
  }, []);

  return (
    <Container ref={ref}>
      {isVideo ? (
        <>
          <video
            src={props.src}
            playsInline={props.playsInline}
            muted={props.muted}
            poster={props.posterImage}
            loop={props.loop}
          />
          <ButtonsContainer>
            <FullScreen
              onClick={() => {
                fullscreenHandler();
              }}
            />
            {isPlaying === 'loading' ? null : isPlaying ? (
              <Pause
                onClick={() => {
                  playPauseHandler();
                }}
              />
            ) : (
              <Play
                onClick={() => {
                  playPauseHandler();
                }}
              />
            )}

            {/* {isMuted ? (
							<MutedSoundOff
								onClick={() => {
									mutedHandler();
								}}
							/>
						) : (
							<MutedSoundOn
								onClick={() => {
									mutedHandler();
								}}
							/>
						)} */}
          </ButtonsContainer>
        </>
      ) : (
        <img {...props} />
      )}
    </Container>
  );
};

export default forwardRef(VideoImageContainer);

import style from './index.module.scss';

const OfficeInfo = (props) => {
  const { textName, address, email, image } = props;
  return (
    <div className={style['office-container']}>
      <div className={style['office-image-container']}>
        <img className={style['office-image']} src={image} />
      </div>
      <div className={style['office-info-container']}>
        <div className={style.name}>{textName}</div>
        <div className={style.address}>
          {address.map((el, index) => (
            <span key={index}>{el}</span>
          ))}
        </div>
        <a href={`mailto:${email}`} className={style.email}>
          {email}
        </a>
      </div>
    </div>
  );
};

export default OfficeInfo;

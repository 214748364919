import { forwardRef } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

const PromptContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  font-family: 'Helvetica Neue', sans-serif;
  justify-content: center;
  transform: translateY(100%);
`;

const Prompt = styled.div`
  width: 100%;
  max-width: 960px;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 20px;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  width: 75%;
  height: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.23px;
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 15px;
  line-height: 1.22;
  letter-spacing: 0.23px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
`;

const CookiePrompt = (props, ref) => {
  const location = useLocation();

  const hashScroll = (el) => {
    const heightOfHeader = document.querySelector('.header').clientHeight;
    let delay = location.pathname.includes('/legal') ? 0 : 500;
    setTimeout(() => {
      const elYCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elYCoordinate - heightOfHeader });
    }, delay);
  };

  return (
    <PromptContainer {...props} ref={ref}>
      <Prompt>
        <Text>
          We use cookies to provide and improve our service to you and store information, including
          your preferences and the pages you look at. By browsing adylic.com you’re agreeing to
          this. Please click{' '}
          <HashLink
            onClick={() => {
              props.click();
            }}
            style={{ textDecoration: 'underline' }}
            scroll={(el) => hashScroll(el)}
            to="/legal#privacy"
          >
            here
          </HashLink>{' '}
          for more information.
        </Text>
        <CloseButton
          onClick={() => {
            props.click();
          }}
        >
          x
        </CloseButton>
      </Prompt>
    </PromptContainer>
  );
};

export default forwardRef(CookiePrompt);

import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import { CSSTransition, Transition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// components
import CaseStudies from 'routes/CaseStudies';
import Home from 'routes/Home';
import HowItWorks from 'routes/HowItWorks';
import About from 'routes/About';
import Legal from 'routes/Legal';
import CookiePrompt from 'components/Other/CookiePrompt';
// import PageNotFound from "routes/PageNotFound";

import 'styles/style.scss';

const App = () => {
  const cookieRef = useRef(null);

  const [showCookiePrompt, setCookiePrompt] = useState(false);
  const [aboutTransitions, setAboutTransitions] = useState('page');

  const cookieClickHandler = () => {
    setCookiePrompt(!showCookiePrompt);
  };

  const duration = 300;

  const defaultStyle = {
    transition: `transform ${duration}ms ease-in-out`,
    transform: 'translateY(100%)'
  };

  const transitionStyles = {
    entering: { transform: 'translateY(100%)' },
    entered: { transform: 'translateY(0)' },
    exiting: { transform: 'translateY(100%)' },
    exited: { transform: 'translateY(0)' }
  };

  // used to prevent fade when clicking on map
  const prevPage = useRef(null),
    location = useLocation();

  // store current path
  prevPage.current = location.pathname;

  //HANDLE SPECIAL ABOUT TRANSITION
  const handleAboutTransition = (locPathName, prevPathName) => {
    if (prevPathName === '/about' && locPathName.includes('/about/'))
      setAboutTransitions('about-location');
  };

  //HANDLE ROUTE EXIT
  const routeExit = () => {
    if (location.hash === '#location' || location.hash === '#privacy') {
      //return console.log('timeout on scroll to top');
      return null
    } else {
      return setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    }
  };

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  useEffect(() => {
    ReactGA.initialize('UA-207371868-1');
    setTimeout(() => {
      setCookiePrompt(true);
    }, 500);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="user-scalable=no, width=device-width, initial-scale=1.0" />
      </Helmet>
      <Route key={'/'} exact path={'/'}>
        {({ match }) => (
          <CSSTransition
            in={match != null}
            timeout={1000}
            classNames="page"
            unmountOnExit
            onExiting={routeExit}
          >
            <Home />
          </CSSTransition>
        )}
      </Route>
      <Route key={'/about'} exact path={'/about'}>
        {({ match }) => (
          <CSSTransition
            in={match != null}
            timeout={1000}
            classNames={aboutTransitions}
            onExited={() => {
              if (aboutTransitions === 'about-location') setAboutTransitions('page');
            }}
            unmountOnExit
            onExiting={routeExit}
          >
            <About handleAboutTransition={handleAboutTransition} />
          </CSSTransition>
        )}
      </Route>
      <Route key={'/about/:location'} exact path={'/about/:location'}>
        {({ match }) => (
          <CSSTransition
            in={match != null}
            timeout={1000}
            classNames={aboutTransitions}
            onEntered={() => {
              if (aboutTransitions === 'about-location') setAboutTransitions('page');
            }}
            unmountOnExit
            onExiting={routeExit}
          >
            <About handleAboutTransition={handleAboutTransition} />
          </CSSTransition>
        )}
      </Route>
      <Route key={'/how-it-works'} exact path={'/how-it-works'}>
        {({ match }) => (
          <CSSTransition
            in={match != null}
            timeout={1000}
            classNames="page"
            unmountOnExit
            onExiting={routeExit}
          >
            <HowItWorks />
          </CSSTransition>
        )}
      </Route>
      <Route key={'/case-studies'} exact path={'/case-studies'}>
        {({ match }) => (
          <CSSTransition
            in={match != null}
            timeout={1000}
            classNames="page"
            unmountOnExit
            onExiting={routeExit}
          >
            <CaseStudies />
          </CSSTransition>
        )}
      </Route>
      <Route key={'/legal'} exact path={'/legal'}>
        {({ match }) => (
          <CSSTransition
            in={match != null}
            timeout={1000}
            classNames="page"
            unmountOnExit
            onExiting={routeExit}
          >
            <Legal />
          </CSSTransition>
        )}
      </Route>

      <Transition in={showCookiePrompt} timeout={duration} appear unmountOnExit>
        {(state) => (
          <CookiePrompt
            click={() => {
              cookieClickHandler();
            }}
            ref={cookieRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              display:'none'
            }}
          />
        )}
      </Transition>
      <a
        className="ot-sdk-btn ot-sdk-show-settings"
        style={{
          position: 'fixed',
          bottom: '0px',
          textAlign: 'left',
          fontSize: '11px',
          padding: '10px',
          opacity: 0.4,
          paddingLeft: '10px',
          cursor: 'pointer'
        }}
      >
        Cookie Settings
      </a>
    </>
  );
};

export default App;

import AntiSlavery from './AntiSlavery';
import style from './index.module.scss';
import LegalNav from './LegalNav';
import Privacy from './Privacy';
import Header from 'components/Header';
import Footer from 'components/Footer';
// import ScrollToTop from "../../helpers/ScrollToTop";
import { useRef } from 'react';

const Legal = () => {
  const navRef = useRef(null),
    antiSlaveryRef = useRef(null),
    privacyRef = useRef(null);

  const clickHandler = (x) => {
    if (x.innerText === 'Top') {
      document.body.scrollIntoView({ behavior: 'smooth' });
    } else if (x.innerText === 'Anti Slavery Policy') {
      antiSlaveryRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (x.innerText === 'Privacy Policy') {
      privacyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className={style.route}>
        <div className={style.container}>
          <Header theme="light" />
          <LegalNav clickHandler={clickHandler} ref={navRef} />
          <AntiSlavery ref={antiSlaveryRef} />
          <Privacy ref={privacyRef} />
          <Footer theme="light" />
        </div>
      </div>
    </>
  );
};

export default Legal;

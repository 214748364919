import { useState, useEffect, createRef, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';
import logoLight from 'assets/adylic-light-logo.svg';
import logoDark from 'assets/adylic-dark-logo.svg';
import menuIconLight from 'assets/menu-icon-light.svg';
import menuIconDark from 'assets/menu-icon-dark.svg';
import closeIconLight from 'assets/close-icon-light.svg';
import closeIconDark from 'assets/close-icon-dark.svg';

import startGsapAnimation from '../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../helpers/generateId';

import gsap, { ScrollTrigger } from 'gsap/all';

const Header = (props) => {
  const isInit = useRef(true);
  const location = useLocation();

  const [menuState, setMenuState] = useState(false);

  const menuClickHandler = () => {
    setMenuState(!menuState);
  };

  const mobileNavHandler = (linkPath) => {
    if (linkPath === location.pathname) menuClickHandler();
  };

  const { theme } = props;

  const mobileMenuRef = createRef(null);

  gsap.defaults({ duration: 0.5 });

  useEffect(() => {
    if (!isInit.current) {
      if (menuState) {
        gsap.fromTo(
          mobileMenuRef.current,
          { autoAlpha: 0, scaleY: 0, pointerEvents: 'none' },
          {
            autoAlpha: 1,
            scaleY: 1,
            pointerEvents: 'all',
            transformOrigin: 'top',
            onComplete: () => {
              gsap.to(mobileMenuRef.current.childNodes, {
                autoAlpha: 1,
                onComplete: () => {
                  gsap.fromTo(
                    mobileMenuRef.current.childNodes[1].childNodes,
                    { autoAlpha: 0, yPercent: 100 },
                    { autoAlpha: 1, stagger: 0.1, yPercent: 0 }
                  );
                }
              });
            }
          }
        );
      } else if (!menuState) {
        gsap.to(mobileMenuRef.current.childNodes[0], { autoAlpha: 0 });
        gsap.to(mobileMenuRef.current.childNodes[1].childNodes, {
          autoAlpha: 0,
          stagger: -0.1,
          onComplete: () => {
            gsap.fromTo(
              mobileMenuRef.current,
              { autoAlpha: 1, scaleY: 1, pointerEvents: 'all', transformOrigin: 'top' },
              {
                autoAlpha: 1,
                scaleY: 0,
                pointerEvents: 'none',
                transformOrigin: 'bottom',
                onComplete: () => {
                  gsap.to(mobileMenuRef.current.childNodes, {
                    autoAlpha: 0,
                    onComplete: () => {}
                  });
                }
              }
            );
          }
        });
      }
    } else {
      isInit.current = false;
    }
  }, [menuState, isInit]);

  //
  //Header background fade-in
  const headerBgRef = useRef(null);
  const headerRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  let tl = null;
  // animation
  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: props.refTest,
        start: '0px',
        end: '100vh',
        scrub: true
        // onUpdate: (self) => {
        //   console.log(`progress ${scrollTriggerId}:, progress: ${self.progress}, start: ${self.start}`);
        // }
      }
    });
    const windowWidth = window.innerWidth;
    const paddingVal = windowWidth < 400 ? '20px 30px' : '20px 50px';
    const headerBgOpacity = theme === 'light' ? 0.75 : 0.55;
    tl.to(headerBgRef.current, { autoAlpha: headerBgOpacity }, 1);
    tl.to(headerRef.current, { padding: paddingVal }, 1);
  };

  const handleNavClass = (currentPath) => {
    return location.pathname === currentPath ? 'current' : '';
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`HEADER ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  return (
    <>
      <div className={`header ${theme}`} ref={headerRef}>
        <div className={`header-background`} ref={headerBgRef}></div>
        <div className="logo-container">
          <Link to="/">
            <img className="logo-image" src={theme === 'light' ? logoLight : logoDark} />
          </Link>
        </div>
        <div className="nav-container">
          <Link className={handleNavClass('/how-it-works')} to="/how-it-works">
            How it Works
          </Link>
          <Link className={handleNavClass('/case-studies')} to="/case-studies">
            Case Studies
          </Link>
          <Link className={handleNavClass('/about')} to="/about">
            About
          </Link>
        </div>
        <div className="nav-container-mobile">
          <div
            className="menu-icon-container"
            onClick={() => {
              menuClickHandler();
            }}
          >
            <img
              className="menu-icon-image"
              src={theme === 'light' ? menuIconLight : menuIconDark}
            />
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${theme}`} ref={mobileMenuRef}>
        <div
          className="close-wrapper"
          onClick={() => {
            menuClickHandler();
          }}
        >
          <div
            className="close-icon-container"
            onClick={() => {
              menuClickHandler();
            }}
          >
            <img
              className="close-icon-image"
              src={theme === 'light' ? closeIconLight : closeIconDark}
            />
          </div>
        </div>
        <div className="mobile-link-container">
          <div>
            <Link
              className={handleNavClass('/how-it-works')}
              to="/how-it-works"
              onClick={() => {
                mobileNavHandler('/how-it-works');
              }}
            >
              How it Works
            </Link>
          </div>
          <div>
            <Link
              className={handleNavClass('/case-studies')}
              to="/case-studies"
              onClick={() => {
                mobileNavHandler('/case-studies');
              }}
            >
              Case Studies
            </Link>
          </div>
          <div>
            <Link
              className={handleNavClass('/about')}
              to="/about"
              onClick={() => {
                mobileNavHandler('/about');
              }}
            >
              About
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

//styles
import './index.scss';

const ArrowButton = (props) => {
  const { colour = '#FFF', className = null, glideDirection = null } = props;

  return (
    <button
      className={className === null ? '' : className}
      data-glide-dir={glideDirection !== null ? glideDirection : ''}
      style={glideDirection !== null ? {} : { pointerEvents: 'none' }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="62" height="39" viewBox="0 0 62 39">
        <path
          fill={colour}
          d="M58.977 14.986c-3.444 3.339-9.027 3.339-12.471 0-3.444-3.338-3.444-8.752-.001-12.09 3.445-3.338 9.028-3.338 12.472 0 3.444 3.338 3.444 8.752 0 12.09zM24.752 36.075c-3.443-3.339-3.443-8.752 0-12.09 3.444-3.339 9.027-3.339 12.472 0 3.443 3.338 3.443 8.751 0 12.09-3.445 3.338-9.028 3.338-12.472 0zM2.999 14.986c-3.444-3.338-3.444-8.752 0-12.09 3.443-3.338 9.027-3.338 12.471 0 3.443 3.338 3.443 8.752 0 12.09-3.444 3.339-9.028 3.339-12.471 0z"
        />
      </svg>
    </button>
  );
};

export default ArrowButton;

import { useRef, forwardRef } from 'react';
import style from './index.module.scss';

const Legal = (props, ref) => {
  const iwoRef = useRef(null),
    hwutiwoRef = useRef(null),
    iwswtpRef = useRef(null),
    yoRef = useRef(null),
    dsRef = useRef(null),
    drRef = useRef(null),
    dtRef = useRef(null),
    ltowRef = useRef(null),
    utoopRef = useRef(null),
    htcuRef = useRef(null),
    ccpsRef = useRef(null);

  return (
    <div className={style.container} ref={ref} id="privacy">
      <section className={style.section}>
        <h1>Online Privacy Notice</h1>
        <p>
          <b>Last updated: January, 2020</b>
        </p>

        <p>
          We at Omnicom Media Group Holdings Inc. (“OMG”), respect your concerns about privacy. This
          Online Privacy Notice applies to personal information we obtain through this website
          (“Site”). This Online Privacy Notice describes the types of personal information we obtain
          through the Site, how we may use the information, with whom we may share it and the
          choices you may have regarding our use of the information. We also describe the measures
          we take to safeguard the information and tell you how to contact us about our privacy
          practices.
        </p>
        <p>
          OMG consists of a network of affiliates, including but not limited to Hearts & Science,
          OMD, PHD, Outdoor Media Group, Optimum Sports/Fuse, Content Collective, Pathway and
          Resolution Media. This Online Privacy Notice only applies to the affiliates on whose
          website this Online Privacy Notice appears. For the privacy practices of our affiliates,
          you should visit their websites to review their own privacy notices.
        </p>

        <p>
          If you are a California consumer and would like information about our online and offline
          privacy practices and your privacy rights, please see the section of this Privacy Notice
          called “California Consumer Privacy Statement.”
        </p>

        <p>Click on one of the links below to jump to the listed section:</p>

        <div className={style['opn-nav']}>
          <ul>
            <li>
              <a onClick={() => iwoRef.current.scrollIntoView({ behavior: 'smooth' })}>
                Information We Obtain
              </a>
            </li>
            <li>
              <a onClick={() => hwutiwoRef.current.scrollIntoView({ behavior: 'smooth' })}>
                How We Use The Information We Obtain
              </a>
            </li>
            <li>
              <a onClick={() => iwswtpRef.current.scrollIntoView({ behavior: 'smooth' })}>
                Information We Share With Third Parties
              </a>
            </li>
            <li>
              <a onClick={() => yoRef.current.scrollIntoView({ behavior: 'smooth' })}>
                Your Options
              </a>
            </li>
            <li>
              <a onClick={() => dsRef.current.scrollIntoView({ behavior: 'smooth' })}>
                Data Security
              </a>
            </li>
            <li>
              <a onClick={() => drRef.current.scrollIntoView({ behavior: 'smooth' })}>
                Data Retention
              </a>
            </li>
            <li>
              <a onClick={() => dtRef.current.scrollIntoView({ behavior: 'smooth' })}>
                Data Transfers
              </a>
            </li>
            <li>
              <a onClick={() => ltowRef.current.scrollIntoView({ behavior: 'smooth' })}>
                Links To Other Websites
              </a>
            </li>
            <li>
              <a onClick={() => utoopRef.current.scrollIntoView({ behavior: 'smooth' })}>
                Updates To Our Online Privacy Notice
              </a>
            </li>
            <li>
              <a onClick={() => htcuRef.current.scrollIntoView({ behavior: 'smooth' })}>
                How To Contact Us
              </a>
            </li>
            <li>
              <a onClick={() => ccpsRef.current.scrollIntoView({ behavior: 'smooth' })}>
                California Consumer Privacy Statement
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className={style.section} ref={iwoRef}>
        <h2>Information We Obtain</h2>
        <p>
          We may obtain the following categories and types of personal information when you choose
          to provide it through our Site:
        </p>
        <ul>
          <li>
            <b>Contact information</b> — such as your name, postal address, email address and
            telephone number;
          </li>
          <li>
            <b>Site information</b> — personal information in content you provide on or through our
            Site; and
          </li>
          <li>
            <b>Biographical information</b> — such as information about your education, work and
            military history, legal work eligibility status, and other information relevant to
            specific jobs for which you may apply.
          </li>
        </ul>
        <p>
          In addition, when you visit our Site, we may collect certain information by automated
          means, such as cookies and web beacons. The information we obtain in this manner may
          include IP address, browser characteristics, device characteristics, operating system,
          language preferences, referring URLs, information on actions taken on our site, and dates
          and times of website visits. A “cookie” is a text file that websites send to a visitor’s
          computer or other Internet-connected device to uniquely identify the visitor’s browser or
          to store information or settings in the browser. A “web beacon,” also known as an Internet
          tag, pixel tag or clear GIF, links web pages to web servers and their cookies and may be
          used to transmit information collected through cookies back to a web server. Through these
          automated collection methods, we obtain “clickstream data,” which is a log of content on
          which a visitor clicks while browsing a website and other actions taken on such website.
          As the visitor clicks through the website, a record of the action may be collected and
          stored. Your browser may tell you how to be notified when you receive certain types of
          cookies or how to restrict or disable certain types of cookies. Please note, however, that
          without cookies you may not be able to use all of the features of our Site.
        </p>

        <p>
          An example of such third party is Google Analytics, which we use to collect and analyze
          demographic and other information, and through which Google will collect certain
          information to be used according to Google’s privacy practices. To opt-out of the use of
          Google Analytics, click here.
        </p>

        <p>
          Information about our cookie practices is available here. To the extent required by
          applicable law, we will obtain your consent before collecting information using cookies or
          similar automated means.
        </p>
      </section>
      <section className={style.section} ref={hwutiwoRef}>
        <h2>How We Use The Information We Obtain</h2>
        <p>We use the information we obtain through the Site to:</p>
        <ul>
          <li>respond to and communicate with you about your requests, questions and comments;</li>
          <li>
            operate, evaluate and improve our business (including developing new products and
            services; managing our communications; determining the effectiveness of our sales,
            marketing and advertising; analyzing and enhancing our products, services and Site; and
            performing accounting, auditing, billing, reconciliation and collection activities);
          </li>
          <li>
            perform data analyses and other processing (including market and consumer research and
            trend analysis);
          </li>
          <li>
            protect against, identify and prevent fraud and other criminal activity, claims and
            other liabilities; and
          </li>
          <li>
            comply with and enforce applicable legal requirements, relevant industry standards and
            our policies.
          </li>
        </ul>
        <p>
          In addition, we use information collected online through cookies, web beacons and other
          automated means for purposes such as (i) customizing our users’ visits to our Site and
          (ii) providing services to you. We also use this information to help diagnose technical
          and service problems, administer our Site, identify users of our Site, and gather
          demographic information about our users. We use clickstream data to determine how much
          time users spend on web pages of our Site, how users navigate through our Site, and how we
          may tailor our Site to better meet the needs of our users.
        </p>
        <p>
          We also may use the information we obtain about you in other ways for which we provide
          specific notice at the time of collection.
        </p>
      </section>
      <section className={style.section} ref={iwswtpRef}>
        <h2>Information We Share With Third Parties</h2>
        <p>
          We do not sell or otherwise share personal information about you, except as described in
          this Online Privacy Notice.
        </p>
        <ul>
          <li>
            We share personal information with third parties who perform services on our behalf.
            These third parties are not authorized by us to use or disclose the information except
            as necessary to perform services on our behalf or comply with legal requirements.
          </li>

          <li>
            We also share the personal information we obtain with our affiliates and subsidiaries
            for the purposes described in the section of this Online Privacy Notice called “How We
            Use The Information We Obtain.”
          </li>

          <li>
            With respect to personal information received or transferred pursuant to the Privacy
            Shield Framework, Omnicom Media Group is subject to the regulatory enforcement powers of
            the U.S. Federal Trade Commission. In addition, we may disclose information about you
            (i) if we are required to do so by law or legal process, (ii) to law enforcement
            authorities or other government officials based on a lawful disclosure request, or (iii)
            when we believe disclosure is necessary or appropriate to prevent physical harm or
            financial loss, or in connection with an investigation of suspected or actual fraudulent
            or illegal activity.
          </li>

          <li>
            We reserve the right to transfer any information we have about you in the event we sell
            or transfer all or a portion of our business or assets (including in the event of a
            reorganization, dissolution, bankruptcy or liquidation).
          </li>
        </ul>
      </section>
      <section className={style.section} ref={yoRef}>
        <h2>Your Options</h2>

        <p>
          We offer you certain choices about how we communicate with you and what information we
          collect from you. To update your preferences, ask us to remove your information from our
          mailing lists or submit a request, please contact us as indicated in the How To Contact Us
          section of this Online Privacy Notice.
        </p>
      </section>
      <section className={style.section} ref={dsRef}>
        <h2>Data Security</h2>

        <p>
          We have put in place reasonable physical, electronic and managerial procedures to
          safeguard the information we collect; however, due to the inherent open nature of the
          Internet, we cannot guarantee that all information will be free from unauthorized access
          by third parties, such as hackers, and your use of the Site demonstrates your assumption
          of this risk.
        </p>
      </section>
      <section className={style.section} ref={drRef}>
        <h2>Data Retention</h2>

        <p>
          We will only retain your personal information for as long as necessary to fulfill the
          purposes for which it was collected, including for satisfying any legal, accounting or
          reporting requirements or to comply with our data retention practices.
        </p>

        <p>
          If you are located in the European Union with rights under the General Data Protection
          Regulation (“GDPR”), we rely on the following legal basis to process and use your personal
          information:
        </p>

        <ul>
          <li>
            It may be necessary for us to use and disclose your personal information for the
            performance and fulfillment of the contract between us and to provide you with our
            services;
          </li>
          <li>
            We may use and disclose your personal information where it is necessary for our
            legitimate interests (or those of a third party) and your interest and fundamental
            rights do not override those interests;
          </li>
          <li>
            If you specifically consent to certain uses of your personal information, we may use
            your personal information in a manner consistent with that consent; and
          </li>
          <li>
            We will also process, transfer, disclose and preserve personal information when we have
            a good faith belief that doing so is necessary.
          </li>
        </ul>
        <p>
          If applicable pursuant to the GDPR, you have the right to withdraw previously provided
          consent for our processing of your personal information by contacting us at
          privacy@omnicommediagroup.com. Such individuals also have the following rights:
        </p>
        <ul>
          <li>
            Right to access — This right allows individuals to obtain confirmation that his or her
            personal information is being process and allows individuals to request details of the
            processing of his or her personal information, including, without limitation, categories
            of recipients to whom the personal information have been or will be disclosed and
            purposes of processing.
          </li>
          <li>
            Right to rectify — This right allows individuals to rectify any inaccurate personal
            information about him or her.
          </li>
          <li>
            Right to restrict processing — This right allows individuals to block or suppress
            processing of personal information under certain circumstances.
          </li>
          <li>
            Right to be forgotten — This right is also known as the “right to erasure.” It is an
            individual’s right to have personal information erased or to prevent processing in
            specific circumstances.
          </li>
          <li>
            Right of data portability — This right allows individuals to move, copy or transfer
            personal information from one place to another in a secure manner without interrupting
            the integrity and usability of the information.
          </li>
          <li>
            Right to object to processing — This right allows individuals to object to certain types
            of processing, including direct marketing, profiling and providing for purposes of
            scientific or historical research and statistics.
          </li>
        </ul>

        <p>
          To exercise any of the following rights, you may contact us at{' '}
          <a href="mailto:privacy@omnicommediagroup.com">privacy@omnicommediagroup.com</a>
        </p>
      </section>
      <section className={style.section} ref={dtRef}>
        <h2>Data Transfers</h2>
        <p>
          Some of the personal information we collect about you through the Site will be transferred
          to countries other than the country in which the information originally was collected,
          including to the United States. Those countries may not have the same data protection laws
          as the country in which you initially provided the information. When we transfer your
          personal information to other countries, we will protect that information as described in
          this Online Privacy Notice and will comply with applicable legal requirements. You
          understand that data (including personal information) stored in the United States may be
          subject to lawful requests by the courts or law enforcement authorities in the United
          States.
        </p>
        <p>
          If you are located in the EEA, with respect to transfers of personal information to the
          U.S., OMG (as well as its affiliates: OMD Worldwide Holdings Inc., Media Services Inc.,
          Resolution Media Inc., Pathway Group LLC, Hearts and Science LLC, OMG Entertainment &
          Sports LLC, PHD Media LLC, OMD USA LLC) complies with the EU-U.S. Privacy Shield Framework
          and the Swiss — U.S. Privacy Shield Framework as set forth by the U.S. Department of
          Commerce regarding the collection, use, and retention of personal information transferred
          from the European Union and Switzerland to the United States, respectively. OMG has
          certified to the Department of Commerce that it adheres to the Privacy Shield Principles.
          If there is any conflict between the terms in this privacy policy and the Privacy Shield
          Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy
          Shield program, and to view our certification, please visit
          <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>.
        </p>
        <p>
          If you have an unresolved privacy or data use concern regarding the EU-U.S. Privacy Shield
          or Swiss-U.S. Privacy Shield Framework that we have not addressed satisfactorily, please
          contact our U.S.-based third party dispute resolution provider (free of charge) at{' '}
          <a href="https://feedback-form.truste.com/watchdog/request">
            https://feedback-form.truste.com/watchdog/request
          </a>
          . OMG is also subject to the investigatory and enforcement powers if the U.S. Federal
          trade Commission.
        </p>
      </section>
      <section className={style.section} ref={ltowRef}>
        <h2>Links To Other Websites</h2>
        <p>
          Our Site may contain links to other websites for your convenience and information. These
          websites may be operated by companies not affiliated with OMG. Linked websites typically
          have their own privacy policies or notices, which we strongly suggest you review if you
          visit any linked websites. We are not responsible for the content of any websites that are
          not affiliated with OMG, any use of those websites, or the privacy practices of those
          websites.
        </p>
      </section>
      <section className={style.section} ref={utoopRef}>
        <h2>Updates To Our Online Privacy Notice</h2>
        <p>
          This Online Privacy Notice will be updated periodically and without prior notice to you to
          reflect changes in our personal information practices. We will post a prominent notice on
          our Site to notify you of any significant changes to our Online Privacy Notice and
          indicate at the top of the notice when it was most recently updated.
        </p>
      </section>
      <section className={style.section} ref={htcuRef}>
        <h2>How To Contact Us</h2>

        <p>
          If you have any questions about this Online Privacy Notice, or if you would like us to
          update information we have about you or your preferences, please contact us by email at{' '}
          <a href="mailto:privacy@omnicommediagroup.com">privacy@omnicommediagroup.com</a> or write
          to us at:
        </p>
        <p>
          Omnicom Media Group Holdings Inc.
          <br />
          Attn: Privacy Counsel
          <br />
          195 Broadway, Floor 8<br />
          New York, NY 10007
        </p>
        <p>If you are in the European Union, please contact:</p>
        <p>
          Omnicom Media Group
          <br />
          Attn: Data Protection Officer
          <br />
          10 Regents Wharf
          <br />
          London
          <br />
          N1 9RL
          <br />
          Tel: +44 (0)203 349 6777
        </p>
      </section>
      <section className={style.section} ref={ccpsRef}>
        <h2></h2>
        <p></p>
      </section>
    </div>
  );
};

export default forwardRef(Legal);

import { useEffect, useRef } from 'react';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import Hero from 'components/Home/Hero';
import Dco from 'components/Home/Dco';
import Philosophy from 'components/Home/Philosophy';
import Important from 'components/Home/Important';
import CaseStudies from 'components/Home/CaseStudies';
import Arrow from 'components/Other/Arrow';
import ArrowContainer from 'components/Other/ArrowContainer';



// styles
import './index.scss';

// import ScrollToTop from "helpers/ScrollToTop";

import startGsapAnimation from '../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../helpers/generateId';

import gsap, { ScrollTrigger } from 'gsap/all';

const data = [
  {
    bg: require('assets/backgrounds/home/bg-light1.jpg').default,
    bg_x2: require('assets/backgrounds/home/bg-light1@2x.jpg').default
  },
  {
    bg: require('assets/backgrounds/home/bg-light2.jpg').default,
    bg_x2: require('assets/backgrounds/home/bg-light2@2x.jpg').default
  },
  {
    bg: require('assets/backgrounds/home/bg-light3.jpg').default,
    bg_x2: require('assets/backgrounds/home/bg-light3@2x.jpg').default
  },
  {
    bg: require('assets/backgrounds/home/bg-light4.jpg').default,
    bg_x2: require('assets/backgrounds/home/bg-light4@2x.jpg').default
  },
  {
    bg: require('assets/backgrounds/home/bg-light5.jpg').default,
    bg_x2: require('assets/backgrounds/home/bg-light5@2x.jpg').default
  }
];

// //STYLED COMPONENTS
// const Hero = styled.div`

// `

const Home = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null);

  let tl = null,
    bgs = [];

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: containerRef.current,
        scrub: true,
        start: 'top 100%',
        end: `${containerRef.current.scrollHeight} 100%`
        // onUpdate: (self) => {
        //   console.log(`progress ${scrollTriggerId}:, progress: ${self.progress}, start: ${self.start}`);
        // }
      }
    });

    tl.fromTo(bgs[0], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[1], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[2], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[3], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[4], { autoAlpha: '0' }, { autoAlpha: '1' });
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`ROUTES > HOME ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="route home" ref={containerRef}>
        <div className="background-container">
          {data.map((bg, i) => (
            <img
              key={i}
              src={bg.bg}
              srcSet={`${bg.bg_x2}, 2x`}
              ref={(el) => {
                bgs.push(el);
              }}
            />
          ))}
        </div>
        <Header theme="light" />
        <div className={`homeRouteWrapper`}>
          <Hero />
          <Arrow colour="#063a53" classProps={'firstType'}/>
          <Dco />
          <ArrowContainer colour="#063a53"/>
          <Philosophy />
          <ArrowContainer colour="#063a53"/>
          <Important />
          <ArrowContainer colour="#063a53"/>
          <CaseStudies />
          <Footer theme="light" />
        </div>
      </div>
    </>
  );
};

export default Home;

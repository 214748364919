/* eslint-disable react/jsx-key */
import { useState, useRef, useEffect } from 'react';

// components
import WheelSlider from './WheelSlider';
import Arrow from 'components/Other/Arrow';
import Wheel from './Wheel';

// styles
import style from './index.module.scss';

// packages
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

const OurServices = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null),
    wheelRef = useRef(null),
    sliderRef = useRef(null);

  let tl = null;

  const data = [
    {
      boxTitle: 'Defining Objectives',
      wheelTitle: ['Defining', <br />, 'Objectives'],
      type: 'media',
      text: 'We work with clients and digital planners to understand business and media KPIs and objectives, and then define the DCO roadmap and strategic framework.',
      colour: '#dab1d3'
    },
    {
      boxTitle: 'Decision Trees',
      wheelTitle: ['Decision', <br />, 'Trees'],
      type: 'media',
      text: 'We identify key data points/triggers and visualize personalized customer journeys that underpin the overall DCO strategy.',
      colour: '#dab1d3'
    },
    {
      boxTitle: 'Test Design',
      wheelTitle: ['Test', <br />, 'Design'],
      type: 'media',
      text: 'We set up up strategy for success by combining scientific methodologies with our approach to creative testing/optimization, ensuring statistically significant results.',
      colour: '#dab1d3'
    },
    {
      boxTitle: 'Template Design & Build',
      wheelTitle: ['Template', <br />, 'Design & Build'],
      type: 'creative',
      text: 'We collaborate with creative teams to design/structure DCO templates (or taking designs provided to us); upon approval, we build templates in our DCO platform.',
      colour: '#96cfbc'
    },
    {
      boxTitle: 'Variant Creation',
      wheelTitle: ['Variant', <br />, 'Creation'],
      type: 'creative',
      text: 'We edit master assets (e.g. imagery, video) and together with provided copy, use our technology to auto-create all audience-relevant creative iterations.',
      colour:'#96cfbc'
    },
    {
      boxTitle: 'Activation Consultancy',
      wheelTitle: ['Activation', <br />, 'Consultancy'],
      type: 'ad-tech',
      text: 'We collaborate with media activation teams to ensure all appropriate digital pipes and plumbing are connected correctly to successfully deliver effective DCO ads.',
      colour: '#d1d1d1'
    },
    {
      boxTitle: 'Ad-Tech Setup',
      wheelTitle: ['Ad-Tech', <br />, 'Setup'],
      type: 'ad-tech',
      text: 'We create, QA and set live the DCO feed in your chosen ad tech;  we are not an ad-server, but have expertise across all major ad tech platforms.',
      colour: '#d1d1d1'
    },
    {
      boxTitle: 'Reporting & Optimisation',
      wheelTitle: ['Reporting &', <br />, 'Optimisation'],
      type: 'ad-tech',
      text: 'We enable granular creative insight, analysis and optimization through our real-time performance reporting dashboard, to ensure continued KPI success.',
      colour: '#d1d1d1'
    }
  ];

  const [currentNumber, setCurrentNumber] = useState(0);
  const [showNumbers, setShowNumbers] = useState('fadeIn');

  // const boxes = data.map((box, i) => (
  //   <WheelBoxItem
  //     number={i}
  //     key={box.title}
  //     boxTitle={box.boxTitle}
  //     wheelTitle={box.wheelTitle.map((el, index) => (
  //       <span key={index}>{el}</span>
  //     ))}
  //     type={box.type}
  //     text={box.text}
  //   />
  // ));

  const changeSlideHandler = (number) => {
    setCurrentNumber(number);
  };

  const textVisibilityHandler = (isVisible) => {
    setShowNumbers(isVisible);
  };

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { id: scrollTriggerId, duration: 0.75, ease: Power3.ease },
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top 100%',
        end: 'bottom 50%',
        markers: false
      }
    });
    tl.fromTo(titleRef.current, { autoAlpha: 0 }, { autoAlpha: 1 });
    tl.fromTo(textRef.current, { autoAlpha: 0 }, { autoAlpha: 1 });
    tl.fromTo(wheelRef.current, { autoAlpha: 0 }, { autoAlpha: 1 });
    tl.fromTo(sliderRef.current, { autoAlpha: 0 }, { autoAlpha: 1 });
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`HOW IT WORKS > OURSERVICES ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  return (
    <div className={style.section} ref={containerRef}>
      <div className={style.container}>
        <div className={style['title-container']}>
          <div className={style.title} ref={titleRef}>
            Our services
          </div>
          <div className={style.text} ref={textRef}>
            Our DCO offering is based around providing end-to-end DCO services that can flex to meet
            each client’s needs.
            <br />
            <br />
            Our services span across key media, creative and ad tech touchpoints, so we collaborate
            and consult closely with these existing agency or in house teams to create bespoke
            solutions that are right for each individual client.
          </div>
        </div>
        <div ref={sliderRef} className={style['wheel-box-container']}>
          <WheelSlider
            data={data}
            changeSlideHandler={changeSlideHandler}
            currentSlide={currentNumber}
          />
        </div>
        {/* <div className={style.left}>
					<div className={style["title-container"]}>
						<div className={style.title} ref={titleRef}>
							Our services
						</div>
						<div className={style.text} ref={textRef}>
							Our DCO offering is based around providing end-to-end DCO services that can flex to meet each client’s needs.
							<br />
							<br />
							Our services span across key media, creative and ad tech touchpoints, so we collaborate and consult closely with these existing agency or in house teams to create bespoke solutions that are right for each individual client.
						</div>
					</div>
					{/* <div className={style["wheel-box-container"]}>{boxes[currentNumber]}</div>  */}
        {/* <WheelSlider data={data} changeSlideHandler={changeSlideHandler} currentSlide={currentNumber} /> */}
        {/* </div> */}
        <div className={style['wheel-container']} ref={wheelRef}>
          <div
            className={style['wheel-skin-lines']}
            style={{ transform: `rotate(-${currentNumber * 45}deg)` }}
          >
            <div className={style['wheel-media']}></div>
            <div className={style['wheel-creative']}></div>
            <div className={style['wheel-adtech']}></div>
          </div>
          <Wheel
            wheelData={data}
            changeSlideHandler={changeSlideHandler}
            textVisibilityHandler={textVisibilityHandler}
            shownNumbers={showNumbers}
            isCurrent={currentNumber}
          />
        </div>
        <Arrow colour="#FFF" direction="down" classProps={style.arrow} />
      </div>
    </div>
  );
};

export default OurServices;

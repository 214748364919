import { useEffect, useRef, useState } from 'react';

// components
import Arrow from 'components/Other/Arrow';
import VideoImageContainer from 'components/Other/VideoImageContainer';
import StatCounter from '../StatCounter';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

// styles
import style from './index.module.scss';

// packages
import gsap, { ScrollTrigger } from 'gsap/all';

const CaseStudy = (props) => {
  const [isInView, setView] = useState(false);

  const {
    isReversed,
    title,
    caseStudyId,
    smallDescription,
    mainDescription,
    image,
    video,
    stats,
    isLastChild,
    isPenultimateChild,
    isFirstChild
  } = props;

  gsap.registerPlugin(ScrollTrigger);

  const container = useRef(null),
    mediaRef = useRef(null),
    mainDescriptionRef = useRef(null),
    statsRef = useRef(null),
    smallTitleRef = useRef(null);

  let tl = null;

  const animate = (scrollTriggerId) => {
    if (isFirstChild) {
      handleVideoPlay();
    }
    tl = gsap.timeline({
      defaults: { duration: 0.5 },
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: container.current,
        markers: false,
        scrub: true,
        pin: true,
        start: 'top',
        end: 'bottom'
      }
    });
    tl.to(
      mediaRef.current,
      {
        onStart: () => {
          if (!isFirstChild) {
            handleVideoPlay();
          }
        },
        scale: 0.9,
        transformOrigin: `${isReversed ? 'right' : 'left'}`
      },
      0
    )
      .to(mainDescriptionRef.current, { autoAlpha: 0 }, 0)
      .to(smallTitleRef.current, { autoAlpha: 0 }, 0)
      .to(statsRef.current, {
        autoAlpha: 1,
        onStart: () => {
          setView(true);
        }
      });
  };

  const handleVideoPlay = () => {
    if (mediaRef.current !== null) {
      if (video) {
        mediaRef.current.childNodes[0].play();
      }
    }
  };

  useEffect(() => {
    mainDescriptionRef.current.innerHTML = mainDescription;
    const scrollTriggerId = generateId();
    // console.log(`HOME > HERO ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  const handleStatsContainerClass = () =>
    stats.length === 2 ? style.twoElem : stats.length === 3 ? style.threeElem : null;

  return (
    <>
      <div className="hashMarker" id={caseStudyId}></div>
      <div className={`${style.section} ${isReversed ? style['is-reversed'] : ''}`} ref={container}>
        <div className={`${style.container} ${isLastChild ? style['last-child'] : ''}`}>
          <div className={style.left}>
            <div className={style['title-container']}>
              <div className={style['small-title']} ref={smallTitleRef}>
                Case study
              </div>
              <div className={style['main-title']}>{title}</div>
              <div className={style['small-description']}>{smallDescription}</div>
            </div>

            <div className={style['description-stats-outer']}>
              <div className={style['main-description']} ref={mainDescriptionRef}>
                {mainDescription === 'Brand awareness lift'
                  ? 'Brand<br>awareness lift'
                  : mainDescription}
              </div>

              <div
                className={`${style['stats-container']} ${handleStatsContainerClass()}`}
                ref={statsRef}
              >
                {stats.map((stat, index) => (
                  <StatCounter
                    key={`${stat.caseStudyId}_${index}`}
                    isInView={isInView}
                    stats={stat}
                    numOfStats={stats.length}
                    caseStudyId={caseStudyId}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={style.right}>
            {image ? (
              <div className={style.imageContainer} ref={mediaRef}>
                <img src={image} />
              </div>
            ) : (
              <VideoImageContainer
                src={video.video}
                ref={mediaRef}
                isVideo={true}
                showMuted={true}
                showFullscreen={true}
                muted={true}
                playsInline={true}
                posterImage={video.poster}
                loop={true}
              />
            )}
          </div>
          {!isLastChild ? (
            <Arrow
              colour="#FFF"
              direction="down"
              classProps={`${style['arrow']} ${
                isPenultimateChild ? style['penultimate-child'] : ''
              }`}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CaseStudy;

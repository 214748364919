import { useEffect, useRef } from 'react';

// packages
import Glide from '@glidejs/glide';
import gsap, { ScrollTrigger } from 'gsap/all';

// components
import ArrowButton from 'components/Other/ArrowButton';

// styles
import './index.scss';

import { HashLink } from 'react-router-hash-link';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

const CaseStudies = () => {
  gsap.registerPlugin(ScrollTrigger);
  const containerRef = useRef(null),
    titleRef = useRef(null),
    carouselRef = useRef(null);

  useEffect(() => {
    new Glide(containerRef.current, {
      type: 'carousel',
      perView: 2,
      focusAt: 'center',
      gap: 25,
      breakpoints: {
        600: {
          perView: 1.25
        }
      }
    }).mount();
  }, []);

  let tl = null;

  const animate = () => {
    tl = gsap.timeline({
      defaults: { duration: 0.5 },
      scrollTrigger: {
        trigger: containerRef.current,
        markers: false
      }
    });

    tl.fromTo(titleRef.current, { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(carouselRef.current, { autoAlpha: '0' }, { autoAlpha: '1' });
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`HOME > CASE STUDIES ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  const hashScroll = (el) => {
    setTimeout(() => {
      const elYCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elYCoordinate });
    }, 500);
  };

  return (
    <div className="section vertical double home case-studies" ref={containerRef}>
      <div className="container">
        <div className="top">
          <div className="title-container" ref={titleRef}>
            <div className="title">Case Studies</div>
          </div>
        </div>
        <div className="bottom">
          <div className="glide" ref={carouselRef}>
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                <li className="glide__slide">
                  <HashLink
                    className="case-study-link"
                    to={`/case-studies#renault`}
                    scroll={(el) => hashScroll(el)}
                  ></HashLink>
                  <img src={require('assets/case-study1.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Renault</div>
                    <div className="slide-text">
                      Delivering production efficiencies through globally-scaled DCO
                    </div>
                  </div>
                </li>
                <li className="glide__slide">
                  <HashLink
                    className="case-study-link"
                    to={`/case-studies#delta`}
                    scroll={(el) => hashScroll(el)}
                  ></HashLink>
                  <img src={require('assets/case-study2.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Delta</div>
                    <div className="slide-text">
                      Real-time automation to improve creative relevancy
                    </div>
                  </div>
                </li>
                <li className="glide__slide">
                  <HashLink
                    className="case-study-link"
                    to={`/case-studies#burts-bees`}
                    scroll={(el) => hashScroll(el)}
                  ></HashLink>
                  <img src={require('assets/case-study3.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Burts Bees</div>
                    <div className="slide-text">
                      Leveraging skin need state data to deliver personalized creative
                    </div>
                  </div>
                </li>
                <li className="glide__slide">
                  <HashLink
                    className="case-study-link"
                    to={`/case-studies#mcdonalds`}
                    scroll={(el) => hashScroll(el)}
                  ></HashLink>
                  <img src={require('assets/case-study4.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">McDonald's</div>
                    <div className="slide-text">Using data-driven creative to drive footfall</div>
                  </div>
                </li>
                <li className="glide__slide">
                  <HashLink
                    className="case-study-link"
                    to={`/case-studies#quaker`}
                    scroll={(el) => hashScroll(el)}
                  ></HashLink>
                  <img src={require('assets/case-study5.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Quaker</div>
                    <div className="slide-text">Driving brand lift through dynamic video</div>
                  </div>
                </li>
                <li className="glide__slide">
                  <HashLink
                    className="case-study-link"
                    to={`/case-studies#audible`}
                    scroll={(el) => hashScroll(el)}
                  ></HashLink>
                  <img src={require('assets/case-study6.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Audible</div>
                    <div className="slide-text">Creating production efficiencies DCO</div>
                  </div>
                </li>
                <li className="glide__slide">
                  <HashLink
                    className="case-study-link"
                    to={`/case-studies#hsbc`}
                    scroll={(el) => hashScroll(el)}
                  ></HashLink>
                  <img src={require('assets/case-study8.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">HSBC</div>
                    <div className="slide-text">
                      Driving performance uplift through iterative testing
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="glide__arrows" data-glide-el="controls">
              <ArrowButton
                colour="#FFF"
                direction="left"
                className="glide__arrow glide__arrow--left"
                glideDirection="<"
              />
              <ArrowButton
                colour="#FFF"
                direction="right"
                className="glide__arrow glide__arrow--right"
                glideDirection=">"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;

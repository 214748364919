/* eslint-disable react/jsx-key */
import { useEffect, useRef } from 'react';

// components
import ImportantPoint from './ImportantPoint';
// import Arrow from 'components/Other/Arrow';

// styles
import style from './index.module.scss';

// packages
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

const pointsData = [
  {
    iconNum: 1,
    image: require('assets/icon1.svg').default,
    title: ['Performance', <br />, 'uplift'],
    text: [
      'DCO creative can be optimised',
      <br />,
      'to key KPIs, delivering uplifts',
      <br />,
      'in media performance'
    ]
  },
  {
    iconNum: 2,
    image: require('assets/icon2.svg').default,
    title: ['Speed to', <br />, 'Market'],
    text: [
      'Content can be updated',
      <br />,
      ' quickly and easily, without the',
      <br />,
      ' need to re-design or re-traffic'
    ]
  },
  {
    iconNum: 3,
    image: require('assets/icon3.svg').default,
    title: ['Flexibility', <br />, 'to Test'],
    text: [
      'Easily test and optimise',
      <br />,
      ' creative strategies against',
      <br />,
      ' different audience targeting'
    ]
  },
  {
    iconNum: 4,
    image: require('assets/icon4.svg').default,
    title: ['Creative', <br />, 'Insight'],
    text: [
      'Leverage data-science to',
      <br />,
      ' inform key insights that feed',
      <br />,
      ' future creative development'
    ]
  },
  {
    iconNum: 5,
    image: require('assets/icon5.svg').default,
    title: ['Efficient &', <br />, 'Scalable'],
    text: [
      'Deliver creative consistency',
      <br />,
      ' when scaling across multiple',
      <br />,
      ' products and/or markets'
    ]
  }
];

const Important = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null),
    pointsContainerRef = useRef(null);

  let titles = [],
    points = [],
    tl = null;

  const importantPoints = pointsData.map((point) => {
    return (
      <ImportantPoint
        key={point.iconNum}
        image={point.image}
        title={point.title.map((el, index) => (
          <span key={index}>{el}</span>
        ))}
        text={point.text.map((el, index) => (
          <span key={index}>{el}</span>
        ))}
        ref={(el) => points.push(el)}
      />
    );
  });

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { duration: 1, ease: Power3.ease },
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: containerRef.current,
        start: 'top center',
        markers: false
        // scrub: 2,
        // once: true
      }
    });

    // tl.fromTo(titles[0], { autoAlpha: 0, yPercent: 50 }, { autoAlpha: 1, yPercent: 0 }, 0);
    // tl.to(titles[0], { autoAlpha: 0, yPercent: -50 }, 4);
    // tl.fromTo(titles[1], { autoAlpha: 0, yPercent: 50 }, { autoAlpha: 1, yPercent: 0 }, 5);
    // tl.to(titles[1], { autoAlpha: 0, yPercent: -50 }, 9);
    // tl.fromTo(titles[2], { autoAlpha: 0, yPercent: 50 }, { autoAlpha: 1, yPercent: 0 }, 10);
    // tl.to(titles[2], { autoAlpha: 0, yPercent: -50 }, 14);
    // tl.fromTo(titles[3], { autoAlpha: 0, yPercent: 50 }, { autoAlpha: 1, yPercent: 0 }, 15);
    // tl.to(titles[3], { autoAlpha: 0, yPercent: -50 }, 19);
    // tl.fromTo(titles[4], { autoAlpha: 0, yPercent: 50 }, { autoAlpha: 1, yPercent: 0 }, 20);

    let animationIncrement = 0;
    let interationCount = 0;
    setInterval(function () {
      if (animationIncrement == 5) {
        animationIncrement = 0;
      }
      tl.fromTo(
        titles[animationIncrement],
        { autoAlpha: 0, yPercent: 50 },
        { autoAlpha: 1, yPercent: 0 },
        interationCount * 5
      );
      tl.to(titles[animationIncrement], { autoAlpha: 0, yPercent: -50 }, interationCount * 5 + 4);
      animationIncrement++;
      interationCount++;
    }, 5000);

    tl.fromTo(
      points[0].childNodes[0],
      { autoAlpha: 0, rotate: 0 },
      { autoAlpha: 1, rotate: 360 },
      0
    );
    tl.fromTo(
      points[1].childNodes[0],
      { autoAlpha: 0, rotate: 0 },
      { autoAlpha: 1, rotate: 360 },
      0.1
    );
    tl.fromTo(
      points[2].childNodes[0],
      { autoAlpha: 0, rotate: 0 },
      { autoAlpha: 1, rotate: 360 },
      0.2
    );
    tl.fromTo(
      points[3].childNodes[0],
      { autoAlpha: 0, rotate: 0 },
      { autoAlpha: 1, rotate: 360 },
      0.3
    );
    tl.fromTo(
      points[4].childNodes[0],
      { autoAlpha: 0, rotate: 0 },
      { autoAlpha: 1, rotate: 360 },
      0.4
    );
    tl.fromTo(points[0].childNodes[1], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.1);
    tl.fromTo(points[1].childNodes[1], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.2);
    tl.fromTo(points[2].childNodes[1], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.3);
    tl.fromTo(points[3].childNodes[1], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.4);
    tl.fromTo(points[4].childNodes[1], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.5);
    tl.fromTo(points[0].childNodes[2], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.2);
    tl.fromTo(points[1].childNodes[2], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.3);
    tl.fromTo(points[2].childNodes[2], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.4);
    tl.fromTo(points[3].childNodes[2], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.5);
    tl.fromTo(points[4].childNodes[2], { autoAlpha: 0 }, { autoAlpha: 1 }, 0.6);
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`HOME > IMPORTANT ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  return (
    <div className={style.section} ref={containerRef}>
      <div className={style.container}>
        <div className={style.top}>
          <div className={style['title-container']}>
            <div className={style.title} ref={(el) => titles.push(el)}>
              Why is DCO important?
            </div>
            <div className={style.title} ref={(el) => titles.push(el)}>
              How can we make your creatives more relevant?
            </div>
            <div className={style.title} ref={(el) => titles.push(el)}>
              How can we automate the production process?
            </div>
            <div className={style.title} ref={(el) => titles.push(el)}>
              How can we respond effectively to a changing environment?
            </div>
            <div className={style.title} ref={(el) => titles.push(el)}>
              How can we deliver improved media performance?
            </div>
            <div className={style.title} ref={(el) => titles.push(el)}>
              How can we further innovate your creatives?
            </div>
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style['important-container']} ref={pointsContainerRef}>
            {importantPoints}
          </div>
        </div>
        {/* <Arrow colour="#063a53" direction="down" className={style.arrow} /> */}
      </div>
    </div>
  );
};

export default Important;

import { Link } from 'react-router-dom';
import style from './index.module.scss';

import SocialIcon from 'components/Other/SocialIcon';

import facebookDarkLogo from 'assets/facebook-dark.svg';
import instagramDarkLogo from 'assets/instagram-dark.svg';
import snapchatDarkLogo from 'assets/snapchat-dark.svg';
import googleDarkLogo from 'assets/google-dark.svg';
// import linkedinDarkLogo from "assets/linkedin-dark.svg";

import facebookLightLogo from 'assets/facebook-light.svg';
import instagramLightLogo from 'assets/instagram-light.svg';
import snapchatLightLogo from 'assets/snapchat-light.svg';
import googleLightLogo from 'assets/google-light.svg';
// import linkedinLightLogo from "assets/linkedin-light.svg";

import footerLogo from 'assets/adylic-brandmark.png';

const MobileFooter = (props) => {
  const { theme } = props;

  let moduleTheme = null;

  if (style.dark.includes(theme)) {
    moduleTheme = style.dark;
  }

  return (
    <div className={`${style.footer} ${moduleTheme}`}>
      {/* <div className={style["sitemap-container"]}> */}
      <Link className={`${style.link} ${style.home}`} to="/">
        Home
      </Link>
      <Link className={`${style.link} ${style.howItWorks}`} to="/how-it-works">
        How it Works
      </Link>
      {/* <Link className={`${style.link} ${style.caseStudies}`} to="/about">
        Case Studies
      </Link> */}
      {/* <div className={style.aboutAspWrapper}> */}
      <Link className={`${style.link} ${style.about}`} to="/about">
        About
      </Link>
      {/* <div> */}
      <Link className={`${style.link} ${style.antiSlavery}`} to="/legal">
        Anti Slavery Policy
      </Link>
      
      <div className={`${style['column-title']} ${style.getInTouch}`}>Get in touch</div>

      <div className={`${style['contacts-inner']} ${style.enquiries}`}>
        <div className={`${style['contact-subtitle']} ${style.enquiries}`}>Enquiries</div>
        <a className={`${style.contactEmail} ${style.enquiries}`} href="mailto:hello@adylic.com">
          hello@adylic.com
        </a>
      </div>

      <div className={`${style['contacts-inner']} ${style.jobs}`}>
        <div className={`${style['contact-subtitle']} ${style.jobs}`}>Jobs</div>
        <a className={`${style.contactEmail} ${style.jobs}`} href="mailto:jobs@adylic.com">
          jobs@adylic.com
        </a>
      </div>

      {/* <div className={style["linkedin-container"]}>
          <a href="https://www.linkedin.com/company/adylic/">
            <SocialIcon image={theme === "light" ? linkedinDarkLogo : linkedinLightLogo} />
          </a>
        </div> */}
      {/* </div> */}

      <div className={style.logosWrapper}>
        <div className={style['marketing-partners']}>
          <div className={style['marketing-title']}>Marketing partners with</div>
          <div className={style['marketing-icons']}>
            <SocialIcon
              className={style['social-icon']}
              image={theme === 'light' ? facebookLightLogo : facebookDarkLogo}
            />
            <SocialIcon
              className={style['social-icon']}
              image={theme === 'light' ? instagramLightLogo : instagramDarkLogo}
            />
            <SocialIcon
              className={style['social-icon']}
              image={theme === 'light' ? snapchatLightLogo : snapchatDarkLogo}
            />
            <SocialIcon
              className={style['social-icon']}
              image={theme === 'light' ? googleLightLogo : googleDarkLogo}
            />
          </div>
        </div>

        <div className={style['footer-logo']}>
          <img src={footerLogo} alt="Adylic brandmark" />
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;

import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import OfficeIcon from './OfficeIcon';
import worldMap from 'assets/world-map.svg';
import OfficeInfo from './OfficeInfo';
import officeList from 'data/office-list';

// import gsap, { ScrollTrigger, Power3 } from "gsap/all";

import { HashLink } from 'react-router-hash-link';

// components
// import Arrow from '../../Other/Arrow';

// styles
import style from './index.module.scss';

// packages

const OurLocations = (props) => {
  const container = useRef(null);

  const [currentLocation, setLocation] = useState('london');
  const [windowWidth, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (props.officeLocation !== undefined) {
      setLocation(props.officeLocation);
    } else {
      setLocation('london');
    }
  }, [props]);

  const history = useHistory();

  const setLocationHandler = (city) => {
    setLocation(city);
    history.push(`/about/${city}`);
  };

  return (
    <div className={style.section} ref={container}>
      <div className={style.container}>
        {/* <Arrow colour="#FFF" direction="down" className={style['arrow']} /> */}
        <div className={style.top}>
          <div className={style['title-container']} id="location">
            <div className={style.title}>Our locations</div>
            <div className={style.text}>
              Adylic operates globally. We are headquartered in London, with offices in &nbsp;
              <br />
              North America, South America, Europe, the Middle East and Australia
            </div>
          </div>
        </div>
        <div className={style['map-container']}>
          <div className={style['map-wrapper']}>
            <img src={worldMap} />
            {officeList.map((office) => (
              <HashLink key={`${office.urlName}_hashLink`} to={`/about/${office.urlName}#location`}>
                <OfficeIcon
                  key={office.urlName}
                  {...office}
                  click={setLocationHandler}
                  isActive={currentLocation}
                />
              </HashLink>
            ))}
          </div>
        </div>
        <div className={style.right}>
          {windowWidth > 600
            ? officeList.map(
                (office) =>
                  office.urlName === currentLocation && (
                    <OfficeInfo key={office.urlName} {...office} />
                  )
              )
            : officeList.map((office) => <OfficeInfo key={office.urlName} {...office} />)}
        </div>
      </div>
    </div>
  );
};

export default OurLocations;

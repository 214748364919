import Particles from 'react-particles-js';

import './index.scss';

const ParticlesContainer = () => {
  return (
    <Particles
      className="particles-container"
      params={{
        particles: {
          number: {
            value: 10,
            density: {
              enable: true,
              value_area: 800
            }
          },
          color: {
            value: '#ffffff'
          },
          shape: {
            type: ['image'],
            image: [
              {
                src: require('assets/particles-icons/icon1.svg').default,
                height: 20,
                width: 20
              },
              {
                src: require('assets/particles-icons/icon2.svg').default,
                height: 20,
                width: 20
              },
              {
                src: require('assets/particles-icons/icon3.svg').default,
                height: 20,
                width: 20
              },
              {
                src: require('assets/particles-icons/icon4.svg').default,
                height: 20,
                width: 20
              },
              {
                src: require('assets/particles-icons/icon5.svg').default,
                height: 20,
                width: 20
              },
              {
                src: require('assets/particles-icons/icon6.svg').default,
                height: 20,
                width: 20
              },
              {
                src: require('assets/particles-icons/icon7.svg').default,
                height: 20,
                width: 20
              },
              {
                src: require('assets/particles-icons/icon8.svg').default,
                height: 20,
                width: 20
              }
            ]
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 10,
            random: false
          },
          line_linked: {
            enable: false
          },
          move: {
            enable: true,
            speed: 1,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200
            }
          }
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'repulse'
            },
            onclick: {
              enable: true,
              mode: 'push'
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
            },
            repulse: {
              distance: 200,
              duration: 0.4
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 2
            }
          }
        },
        retina_detect: true
      }}
    />
  );
};

export default ParticlesContainer;

// packages
import CountUp from 'react-countup';

// styles
import style from './index.module.scss';

const StatCounter = (props) => {
  const { isInView, stats, numOfStats, caseStudyId } = props;

  const handleArrowDirection = () => (stats.isMinus ? 'down' : 'up');
  const handleArrowColour = () => (stats.isMinus ? 'neg' : 'pos');
  const handleCountUpDecimals = () =>
    stats.value.toString().includes('.') ? Number(1) : Number(0);
  const handleInnerWidthClass = () =>
    numOfStats === 2 ? style.twoElem : numOfStats === 3 ? style.threeElem : null;
  const handleStatNumClass = () => (caseStudyId === 'mcdonalds' ? style.reduced : '');

  const handleUnitsContent = () => {
    const unitClass = stats.units === '+' ? 'plus' : stats.units === 'X' ? 'times' : '';
    return <sup className={`${style.unitSymbol} ${style[unitClass]}`}>{stats.units}</sup>;
  };

  return (
    <div className={`${style['stat-inner']} ${handleInnerWidthClass()}`}>
      <div className={`${style['stat-arrow']}`}>
        <img
          src={
            require(`assets/info-arrow-${handleArrowDirection()}-${handleArrowColour()}.svg`)
              .default
          }
        />
      </div>

      <div className={`${style.number}`}>
        <div className={style['stat-title']}>{stats.name}</div>
        <div className={`${style['stat-num']} ${handleStatNumClass()}`}>
          <div className={style.numWrapper}>
            {isInView ? (
              <CountUp
                decimal={`<span class=${style.decimal}>.</span>`}
                decimals={handleCountUpDecimals()}
                start={0}
                end={Number(stats.value)}
              />
            ) : (
              0
            )}
          </div>
          <div className={style.unitsWrapper}>{handleUnitsContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default StatCounter;

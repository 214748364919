import { forwardRef } from 'react';
import style from './index.module.scss';

const LegalNav = (props, ref) => {
  return (
    <div className={style.container} ref={ref}>
      {/* <a
				onClick={(e) => {
					props.clickHandler(e.target);
				}}>
				Top
			</a>
			<div className={style.spacer}></div> */}
      <a
        onClick={(e) => {
          props.clickHandler(e.target);
        }}
      >
        Anti Slavery Policy
      </a>
      <div className={style.spacer}></div>
      <a
        onClick={(e) => {
          props.clickHandler(e.target);
        }}
      >
        Privacy Policy
      </a>
    </div>
  );
};

export default forwardRef(LegalNav);

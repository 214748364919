import Arrow from '../Arrow';

const ArrowContainer = (props) => {
  const { colour = '#FFF', classProps = null} = props;

  return (
    <div className={`arrowContainer`} >
      <Arrow colour={colour} classProps={classProps} />
    </div>
  );
};

export default ArrowContainer;

import { useState, useEffect } from 'react';

import './index.scss';

// Import Swiper React components
import SwiperCore, { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination]);

const WheelSlider = (props) => {
  const [swiper, setSwiper] = useState(null);

  // TODO
  // this will cause an error in console about bad state, commenting
  // this out will fix it but this is needed to make be able to access instance
  // to access the api, and update slider when wedge is clicked
  useEffect(() => {
    setSwiper(document.querySelector('.swiper-container').swiper);
  }, []);

  if (swiper !== null) {
    swiper.slideTo(props.currentSlide);
  }

  const boxes = props.data.map((box, i) => (
    <SwiperSlide key={i} className="mobile-wheel-box">
      <div className="mobile-wheel-box-title-container">
        <div className="mobile-wheel-box-number" style={{color: box.colour, border: `${box.colour} solid`}}>{i + 1}</div>
        <div className="mobile-wheel-box-title">{box.boxTitle}</div>
      </div>
      <div className="mobile-wheel-box-text-container">
        <div className="mobile-wheel-box-text">{box.text}</div>
      </div>
    </SwiperSlide>
  ));

  const swiperParams = {
    spaceBetween: 25,
    pagination: true,
    className: 'swiper-container',
    onSlideChange: ({ activeIndex }) => props.changeSlideHandler(activeIndex),
    speed: 2000,
    breakpoint: {
      600: {
        spaceBetween: 50
      }
    }
  };

  return (
    <>
      <Swiper {...swiperParams}>{boxes}</Swiper>
    </>
  );
};

export default WheelSlider;

import { forwardRef } from 'react';

import style from './index.module.scss';

const ImportantPoint = (props, ref) => {
  const { image, title, text } = props;
  return (
    <div className={style['important-point-container']} ref={ref}>
      <img className={style['important-image']} src={image} />
      <div className={style['important-title']}>{title}</div>
      <div className={style['important-text']}>{text}</div>
    </div>
  );
};

export default forwardRef(ImportantPoint);

import { useEffect, useRef } from 'react';

// styles
import style from './index.module.scss';

// components
// import Arrow from 'components/Other/Arrow';
import JobsItem from 'components/About/OurTeam/JobsItem';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

// packages
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

const jobs = [
  {
    image: require('assets/icon1.svg').default,
    title: 'CREATIVE STRATEGISTS'
  },
  {
    image: require('assets/icon2.svg').default,
    title: 'DIGITAL DESIGNERS'
  },
  {
    image: require('assets/icon3.svg').default,
    title: 'CLIENT SERVICING'
  },
  {
    image: require('assets/icon4.svg').default,
    title: 'HTML5 DEVELOPERS'
  },
  {
    image: require('assets/icon5.svg').default,
    title: 'AD OPS SPECIALISTS'
  },
  {
    image: require('assets/icon6.svg').default,
    title: 'PRODUCT MANAGERS'
  },
  {
    image: require('assets/icon7.svg').default,
    title: 'DATA ANALYSTS'
  },
  {
    image: require('assets/icon8.svg').default,
    title: 'PLATFORM DEVELOPERS'
  }
];

const Hero = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null),
    imageRef = useRef(null),
    jobsContainerRef = useRef(null),
    textTitleContainerRef = useRef(null);

  let bulletList = [],
    tl = null;

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { duration: 1, ease: Power3.ease },
      scrollTrigger: { id: scrollTriggerId, start: 'top center' }
    });

    tl.to(titleRef.current, { autoAlpha: 1, y: 0 }, 0.25);
    tl.to(textRef.current, { autoAlpha: 1, y: 0 }, 0.75);
    tl.to(imageRef.current, { autoAlpha: 1, x: 0 }, 0.75);

    bulletList.forEach((bullet) => {
      tl.fromTo(
        bullet.childNodes[0],
        { autoAlpha: 0, rotate: 0, scale: 0 },
        { autoAlpha: 0.5, rotate: 360, scale: 1, duration: 0.5 },
        '-=0.4'
      );
      // tl.fromTo(bullet.childNodes[0], { autoAlpha: 0, rotate: 0, scale: 0 }, { autoAlpha: 1, rotate: 360, scale: 1, duration: 0.5 }, "-=0.4");	//illegibility issue
      tl.fromTo(bullet.childNodes[1], { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 }, '-=0.4');
    });
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`HOME > HERO ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  const jobsItems = jobs.map((job, i) => (
    <JobsItem key={i} {...job} ref={(el) => bulletList.push(el)} />
  ));

  return (
    <div className={style.section} ref={containerRef}>
      <div className={style.container}>
        <div className={style.left}>
          <div className={style['title-container']} ref={textTitleContainerRef}>
            <div className={style.title} ref={titleRef}>
              Our team
            </div>
            <div className={style.text} ref={textRef}>
              We’re a diverse and passionate bunch of specialists,&nbsp;
              <br />
              ranging from designers with technical know-how,&nbsp;
              <br />
              creative strategists who get to the heart of our client’s needs,&nbsp;
              <br />
              and developers who push ad-tech innovation.
            </div>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.imageContainer} ref={imageRef}>
            <img src={require('assets/about-hero-image.jpg').default} />{' '}
          </div>
        </div>
        <div className={style.bottom} ref={jobsContainerRef}>
          <div className={style['jobs-container']}>{jobsItems}</div>
        </div>
        {/* <Arrow colour="#FFF" direction="down" className={style['arrow']} /> */}
      </div>
    </div>
  );
};

export default Hero;

// components
// import Arrow from "components/Other/Arrow";
import ParticlesContainer from 'components/Other/ParticlesContainer';
import DesktopHero from './DesktopHero';
import MobileHero from './MobileHero';

// styles
import style from './index.module.scss';

// packages

const Hero = () => {
  return (
    <div className={`${style.section}`}>
      <ParticlesContainer />
      <DesktopHero />
      <MobileHero />
    </div>
  );
};

export default Hero;

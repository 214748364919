import { useEffect, useRef } from 'react';

// components
import SocialIcon from 'components/Other/SocialIcon';
import ArrowButton from 'components/Other/ArrowButton';

// packages
import Glide from '@glidejs/glide';
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

// styles
import './index.scss';

const WhatWereLike = () => {
  gsap.registerPlugin(ScrollTrigger);
  const containerRef = useRef(null),
    carouselRef = useRef(null),
    topRef = useRef(null),
    middleRef = useRef(null),
    bottomRef = useRef(null);

  let tl = null;

  const initGlide = () => {
    new Glide(carouselRef.current, {
      type: 'carousel',
      perView: 2,
      focusAt: 'center',
      gap: 25,
      breakpoints: {
        600: {
          perView: 1.25
        }
      }
    }).mount();
  };

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { duration: 1, ease: Power3.ease },
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: containerRef.current,
        start: 'top center',
        markers: false
      }
    });

    tl.fromTo(topRef.current, { autoAlpha: '0' }, { autoAlpha: '1' }, 0);
    tl.fromTo(middleRef.current, { autoAlpha: '0' }, { autoAlpha: '1' }, 0.5);
    tl.fromTo(bottomRef.current, { autoAlpha: '0' }, { autoAlpha: '1' }, 1);
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`HOME > HERO ID: ${scrollTriggerId}`);
    initGlide();
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  return (
    <div className="section vertical triple about what-were-like" ref={containerRef}>
      <div className="container">
        <div className="top" ref={topRef}>
          <div className="title-container">
            <div className="title">What we're like...</div>
          </div>
        </div>
        <div className="middle" ref={middleRef}>
          <div className="glide" ref={carouselRef}>
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                <li className="glide__slide">
                  <img src={require('assets/bitNerdy.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Bit Nerdy</div>
                    <div className="slide-text">
                      We love being techie and don't mind getting stuck into some stats now and
                      again.
                    </div>
                  </div>
                </li>
                <li className="glide__slide">
                  <img src={require('assets/dynamic.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Dynamic</div>
                    <div className="slide-text">Obviously!</div>
                  </div>
                </li>
                <li className="glide__slide">
                  <img src={require('assets/fearless.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Fearless</div>
                    <div className="slide-text">
                      Trailblazing the evolution of marketing means actively encouraging risk taking
                      and ensuring we all learn from the mistakes we make.
                    </div>
                  </div>
                </li>
                <li className="glide__slide">
                  <img src={require('assets/closeKnit.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Close Knit</div>
                    <div className="slide-text">
                      We’re a diverse bunch but have a bond which runs deep. We quite like
                      socializing together too.{' '}
                    </div>
                  </div>
                </li>
                <li className="glide__slide">
                  <img src={require('assets/dedicated.jpg').default} />
                  <div className="slide-container end">
                    <div className="slide-title">Dedicated</div>
                    <div className="slide-text">
                      We’re passionate about our work and are committed to delivering an exceptional
                      service to our clients.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="glide__arrows" data-glide-el="controls">
              <ArrowButton
                colour="#FFF"
                direction="left"
                className="glide__arrow glide__arrow--left"
                glideDirection="<"
              />
              <ArrowButton
                colour="#FFF"
                direction="right"
                className="glide__arrow glide__arrow--right"
                glideDirection=">"
              />
            </div>
          </div>
        </div>
        <div className="bottom" ref={bottomRef}>
          <div className="title-container">
            <div className="title-wrapper">
              <div className="title">fancy joining the team?</div>
              <div className="text">
                Send your CV to <a href="mailto:jobs@adylic.com">jobs@adylic.com</a>
              </div>
            </div>
            <div className="linkedin-wrapper">
              <a href="https://www.linkedin.com/company/adylic/">
                <SocialIcon image={require('assets/linkedin-light.svg').default} />
              </a>
            </div>
          </div>
        </div>
        {/* <Arrow colour="#FFF" direction="down" className="arrow" /> */}
      </div>
    </div>
  );
};

export default WhatWereLike;

/* eslint-disable react/jsx-key */
import londonImage from 'assets/london.jpg';
import hamburgImage from 'assets/hamburg.jpg';
import dusseldorfImage from 'assets/dusseldorf.jpg';
import newYorkImage from 'assets/new-york.jpg';
import losAngelesImage from 'assets/los-angeles.jpg';
import miamiImage from 'assets/miami.jpg';
import dubaiImage from 'assets/dubai.jpg';
import sydneyImage from 'assets/sydney.jpg';
import santiagoImage from 'assets/santiago.jpg';

const officeList = [
  {
    textName: 'London',
    urlName: 'london',
    address: [
      'Bankside 2,',
      <br />,
      '90-100 Southwark Street,',
      <br />,
      'London,',
      <br />,
      'SE1 0SW'
    ],
    image: londonImage,
    email: 'adylic-london@adylic.com',
    mapX: 40.75,
    mapY: 19
  },
  {
    textName: 'Hamburg',
    urlName: 'hamburg',
    // address: ["Bankside 2,", <br />, "90-100 Hamburg Street,", <br />, "Hamburg,", <br />, "H09 9AX"],
    address: ['Zirkusweg 1', <br />, '20359', <br />, 'Hamburg', <br />, 'Germany'],
    image: hamburgImage,
    email: 'adylic-hamburg@adylic.com',
    mapX: 43.75,
    mapY: 20
  },
  {
    textName: 'Düsseldorf',
    urlName: 'dusseldorf',
    address: ['Grünstraße 15', <br />, '40212', <br />, 'Düsseldorf', <br />, 'Germany'],
    image: dusseldorfImage,
    email: 'adylic-dusseldorf@adylic.com',
    mapX: 42.2,
    mapY: 22
  },
  {
    textName: 'New York',
    urlName: 'new-york',
    // address: ["Bankside 2,", <br />, "90-100 New York Street,", <br />, "New York,", <br />, "New Y09 9AX"],
    address: ['195 Broadway', <br />, 'New York City', <br />, 'New York', <br />, '10007'],
    image: newYorkImage,
    email: 'newyork@adylic.com',
    mapX: 21,
    mapY: 26
  },
  {
    textName: 'Los Angeles',
    urlName: 'los-angeles',
    // address: ["Bankside 2,", <br />, "90-100 Los Angeles Street,", <br />, "Los Angeles,", <br />, "Los A09 9AX"],
    address: [
      '5353 Grosvenor Boulevard',
      <br />,
      'Los Angeles',
      <br />,
      'California',
      <br />,
      '90066'
    ],
    image: losAngelesImage,
    email: 'adylic-la@adylic.com',
    mapX: 6,
    mapY: 30
  },
  {
    textName: 'Miami',
    urlName: 'miami',
    // address: ["Bankside 2,", <br />, "90-100 Miami Street,", <br />, "Miami,", <br />, "M09 9AX"],
    address: ['6205 Blue Lagoon Drive', <br />, 'Miami', <br />, 'Florida', <br />, '33126'],
    image: miamiImage,
    email: 'adylic-miami@adylic.com',
    mapX: 16.75,
    mapY: 38
  },
  {
    textName: 'Dubai',
    urlName: 'dubai',
    // address: ["Bankside 2,", <br />, "90-100 Dubai Street,", <br />, "Dubai,", <br />, "D09 9AX"],
    address: [
      'Omnicom Media Group Building',
      <br />,
      'Al Sunbolah Street',
      <br />,
      'Dubai Media City',
      <br />,
      'Dubai'
    ],
    image: dubaiImage,
    email: 'adylic-dubai@adylic.com',
    mapX: 56,
    mapY: 38
  },
  {
    textName: 'Sydney',
    urlName: 'sydney',
    // address: ["Bankside 2,", <br />, "90-100 Sydney Street,", <br />, "Sydney,", <br />, "S09 9AX"],
    address: ['Bay 7', <br />, '2 Locomotive Street', <br />, 'Eveleigh', <br />, 'NSW 2015'],
    image: sydneyImage,
    email: 'adylic-sydney@adylic.com',
    mapX: 84.5,
    mapY: 82
  },
  {
    textName: 'Santiago',
    urlName: 'santiago',
    // address: ["Bankside 2,", <br />, "90-100 Santiago Street,", <br />, "Santiago,", <br />, "S09 9AX"],
    address: [
      'Andrés Bello 2115 - Piso 9',
      <br />,
      'Providencia',
      <br />,
      'Santiago',
      <br />,
      'Chile'
    ],
    image: santiagoImage,
    email: 'adylic-santiago@adylic.com',
    mapX: 20,
    mapY: 78
  }
];

export default officeList;

import './index.scss';

export default function OfficeIcon(props) {
  const { urlName, isActive, mapX, mapY, click } = props;

  return (
    <div
      className={`office-icon ${urlName} ${urlName === isActive ? ' active ' : ''}`}
      style={{ left: mapX + '%', top: mapY + '%' }}
      onClick={() => {
        click(urlName);
      }}
    ></div>
  );
}

import { forwardRef } from 'react';

import style from './index.module.scss';

// eslint-disable-next-line no-unused-vars
const Wheel = (props, ref) => {
  const wedges = props.wheelData.map((wedge, i) => (
    <div
      key={i}
      className={`${style.wedge} ${style[wedge.type]} ${
        props.isCurrent == i ? style['activeWedge'] : style['nonActive']
      }`}
      style={{
        transform: `rotate(${(i * 360) / props.wheelData.length}deg) skewY(${
          360 / props.wheelData.length - 90
        }deg)`
      }}
      onClick={() => {
        props.changeSlideHandler(i);
        props.textVisibilityHandler('fadeOut');
      }}
    >
      <div
        className={`${style.text} ${style[props.shownNumbers]}`}
        style={{
          transform: `skewY(${-(360 / props.wheelData.length - 90)}deg) rotate(${
            360 / props.wheelData.length / 2
          }deg)`
        }}
      >
        <div className={style.border}></div>
        <div className={style.number}>{`${i + 1}`}</div>
        <div className={style.title}>{wedge.wheelTitle}</div>
        <div className={style.topBorder}></div>
      </div>
      <div className={style.activeBorder}></div>
    </div>
  ));
  return (
    <div className={style['wheel-skin']}>
      <div className={style['wheel-central-logo']}></div>
      <div
        className={style.wheel}
        onTransitionEnd={() => {
          props.textVisibilityHandler('fadeIn');
        }}
        style={{ transform: `rotate(-${props.isCurrent * 45 + 22.5}deg)` }}
      >
        {wedges}
      </div>
    </div>
  );
};

export default forwardRef(Wheel);

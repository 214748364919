import { useEffect, useRef } from 'react';

// components
import Arrow from 'components/Other/Arrow';

// styles
import style from './index.module.scss';

// packages
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

import startGsapAnimation from '../../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../../helpers/generateId';

const pointsData = [
  {
    text: 'DCO strategy & thinking',
    icon: require('assets/icon1.svg').default
  },
  {
    text: 'Our services',
    icon: require('assets/icon2.svg').default
  },
  {
    text: 'Platform & channels',
    icon: require('assets/icon3.svg').default
  },
  {
    text: 'Optimization & insight',
    icon: require('assets/icon4.svg').default
  }
];

const DesktopHero = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef(null),
    titleRef = useRef(null);

  let bulletList = [];

  const heroBullets = pointsData.map((bullet) => (
    <div
      key={bullet.text}
      className={style.bullet}
      ref={(el) => {
        bulletList.push(el);
      }}
    >
      <div className={style['bullet-image-container']}>
        <img className={['bullet-image']} src={bullet.icon} />
      </div>
      <div className={style['bullet-title-container']}>
        <div className={['bullet-title']}>{bullet.title}</div>
        {bullet.text && <div className={['bullet-text']}>{bullet.text}</div>}
      </div>
    </div>
  ));

  // timeline, null before init
  let tl = null;

  // animation
  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { duration: 1, ease: Power3.ease },
      scrollTrigger: {
        id: scrollTriggerId,
        start: 'top center'
      }
    });

    tl.to(titleRef.current.children, { autoAlpha: 1, y: 0, stagger: 0.25 }, 0.75);
    tl.to(bulletList, { autoAlpha: 1, y: 0, stagger: 0.25 });
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`HOW IT WORKS > HERO ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  return (
    <div>
      <div className={`${style.section}`} ref={containerRef}>
        <div className={style.container}>
          <div className={style.contentWrapper}>
            <div className={style['title-container']}>
              <div className={style.title} ref={titleRef}>
                <div className={style['title-line']}>We take the</div>
                <div className={style['title-line']}>guesswork</div>
                <div className={style['title-line']}>out of</div>
                <div className={style['title-line']}>creativity</div>
              </div>
            </div>

            <div className={style['bullet-wrapper']}>
              <div className={style['bullet-container']}>{heroBullets}</div>
            </div>
          </div>

          <Arrow colour="#ffffff" direction="down" classProps={style.arrow} />
        </div>
      </div>
    </div>
  );
};

export default DesktopHero;

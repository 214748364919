import { Curtains } from 'react-curtains';
import SimplePlane from './SimplePlane';

import './HeroLogo.css';

const Hero = () => {
  return (
    <Curtains>
      <SimplePlane />
    </Curtains>
  );
};

export default Hero;

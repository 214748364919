import { useRef, useEffect } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Hero from 'components/About/Hero';
import WhatWereLike from 'components/About/WhatWereLike';
import OurLocations from 'components/About/OurLocations';
import ArrowContainer from 'components/Other/ArrowContainer';

import startGsapAnimation from '../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../helpers/generateId';

import './index.scss';

import gsap, { ScrollTrigger } from 'gsap/all';

const data = [
  {
    bg: require('assets/backgrounds/dark/bg-dark1.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark1.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark2.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark2.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark3.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark3.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark4.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark4.jpg').default
  },
  {
    bg: require('assets/backgrounds/dark/bg-dark5.jpg').default,
    bg_x2: require('assets/backgrounds/dark/bg-dark5.jpg').default
  }
];

const About = (props) => {
  const { handleAboutTransition } = props;
  gsap.registerPlugin(ScrollTrigger);
  const containerRef = useRef(null);

  let tl = null,
    bgs = [];

  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      scrollTrigger: {
        id: scrollTriggerId,
        trigger: containerRef.current,
        markers: false,
        scrub: true,
        start: 'top 100%',
        end: `${containerRef.current.scrollHeight} 100%`
        // onUpdate: (self) => {
        //   console.log(`progress ${scrollTriggerId}:, progress: ${self.progress}, start: ${self.start}`);
        // }
      }
    });

    tl.fromTo(bgs[0], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[1], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[2], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[3], { autoAlpha: '0' }, { autoAlpha: '1' });
    tl.fromTo(bgs[4], { autoAlpha: '0' }, { autoAlpha: '1' });
  };

  useEffect(() => {
    const scrollTriggerId = generateId();
    // console.log(`ROUTES > ABOUT ID: ${scrollTriggerId}`);
    startGsapAnimation(scrollTriggerId, animate);
    return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
  }, []);

  const { location } = useParams();
  // const { hash } = useLocation()

  //HANDLE SPECIAL TRANSITION
  const locationObj = useLocation();
  const locRef = useRef(useLocation());
  handleAboutTransition(locationObj.pathname, locRef.current.pathname);

  return (
    <>
      {/* {hash !== '#location' ? <ScrollToTop /> : <ScrollToTopAlt />} */}
      <div className="route about" ref={containerRef}>
        <div className="background-container">
          {data.map((bg, i) => (
            <img
              key={i}
              src={bg.bg}
              srcSet={`${bg.bg_x2}, 2x`}
              ref={(el) => {
                bgs.push(el);
              }}
            />
          ))}
        </div>
        <Header theme="dark" />
        <div className={`aboutRouteWrapper`}>
          <Hero />
          <ArrowContainer  classProps={'firstType'}/>
          <WhatWereLike />
          <ArrowContainer />
          <OurLocations officeLocation={location !== 'undefined' ? location : 'london'} />
          <Footer theme="dark" />
        </div>
      </div>
    </>
  );
};

export default About;

import { forwardRef } from 'react';
import style from './index.module.scss';

const AntiSlavery = (props, ref) => {
  return (
    <div className={style.container} ref={ref}>
      <section className={style.section}>
        <h1>Omnicom Media Group Europe Limited Statement on the Modern Slavery Act</h1>
        <p>
          <b>Last Updated: October 2023 </b>
        </p>
        <p>
          This statement, which has been approved by the Board of Omnicom Media Group Europe
          Limited, is made pursuant to section 54(1) of the Modern Slavery Act 2015 and constitutes
          our modern slavery and human trafficking statement for the financial year ending 31
          December 2022.
        </p>
        <p>
          This statement is made on behalf of Omnicom Media Group Europe Limited, and each of its
          subsidiaries listed at the end of this statement, (together “we”, “our”, “us”).
        </p>
        <p>
          We foster a culture of collaboration and focus on investing in our people, protecting the
          environment and giving back to the communities in which we operate. We have a
          zero-tolerance approach to any form of modern slavery and are committed to establishing
          effective systems and controls to safeguard against any form of modern slavery or human
          trafficking in our supply chain.
        </p>
      </section>
      <section className={style.section}>
        <h2>Our business</h2>
        <p>
          We are leading media services companies and our portfolios provide the best talent,
          creativity, technology and innovation to some of the world's most iconic and successful
          brands. We always strive to work to the highest professional standards and comply with all
          laws, regulations and rules relevant to our business.
        </p>
      </section>
      <section className={style.section}>
        <h2>Our supply chain</h2>
        <p>
          Our key suppliers are media owners, media suppliers, research companies, technology
          service providers and software service providers. Modern forms of slavery and human
          trafficking are not prevalent in the industries in which these suppliers operate. We
          expect the same high standards as those to which we hold ourselves from those businesses
          with which we work.
        </p>
        <p>
          We believe the nature of our work, our supply chains and their industries to be low risk
          in terms of modern slavery and human trafficking.
        </p>
      </section>
      <section className={style.section}>
        <h2>Policies</h2>
        <p>
          Our corporate responsibility strategy, codified in the Omnicom Group Code of Business
          Conduct, aims to improve the impact of our business on society and we are committed to
          ensuring that there is no modern slavery or human trafficking in any part of our supply
          chains or in any part of our business.
        </p>
        <p>
          Our Supplier Code of Conduct reflects our commitment to acting ethically and with
          integrity in our business relationships and to implementing and enforcing effective
          systems and controls to ensure modern slavery and human trafficking is not taking place
          anywhere in our supply chains. A copy of our Supplier Code of Conduct can be supplied upon
          request.
        </p>
        <p>
          We have taken the following steps since the last publication of our Modern Slavery Act
          statement:
        </p>
        <ol>
          <li>
            we have continued to update existing websites so that they contain the latest copy of
            our Modern Slavery Act statement and Supplier Code of Conduct;
          </li>
          <li>
            we have continued to communicate our Supplier Code of Conduct to our suppliers, in
            particular to digital media owners via our Digital Booking Terms & Conditions (a copy of
            which is available upon request);
          </li>
          <li>
            we are continuing with our endeavour to negotiate express warranties and indemnities in
            our contracts with new suppliers regarding compliance with the Modern Slavery Act 2015
            and our Supplier Code of Conduct;
          </li>
          <li>
            we have again identified that modern slavery and human trafficking are not prevalent in
            the industries of our key suppliers, and that our suppliers are not located in markets
            where there is a high risk and level of exposure to modern slavery and human
            trafficking; and
          </li>
          <li>
            we are rolling out some training for selected members of staff across the group. Due to
            our zero- tolerance approach to any form of modern slavery, we feel that some of our
            staff would benefit from having a clear understanding of (and ability to identify) forms
            of modern slavery and human trafficking, as well as how to combat them.
          </li>
        </ol>
        <p>
          We continue to review our policies and processes in an effort to go beyond basic social
          and environmental regulations, to ensure the highest standards in our supply chain (as
          expected by our customers and the companies with which we work) and to ensure that no form
          of forced labour or slavery is present in our business or our supply chain.
        </p>
      </section>
      <section className={style.section}>
        <p>
          This statement constitutes the modern slavery and human trafficking statement for Omnicom
          Media Group Europe Limited and the following subsidiaries:
        </p>
        <ol>
          <li>Adylic Limited</li>
          <li>Drum OMG Limited</li>
          <li>Empyrean OMG Limited</li>
          <li>Hearts and Science Limited</li>
          <li>Fuse International Limited</li>
          <li>Mobile5 Media Limited</li>
          <li>OMD EMEA Limited</li>
          <li>OMD Group Limited</li>
          <li>Omnicom Media Group UK Limited</li>
          <li>PHD International Limited</li>
          <li>PHD Media Limited</li>
          <li>TRKKN UK Limited (previously named Resolution Media UK Limited)</li>
        </ol>
      </section>
      <section className={style.section}>
        <p>
          Peter Poelzlbauer
          <br />
          CFO and Director
          <br />
          For and on behalf of Omnicom Media Group Europe Limited and the subsidiaries listed above
        </p>
      </section>
    </div>
  );
};

export default forwardRef(AntiSlavery);

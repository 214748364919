const killScrollTrigger = (scrollTriggerId, scrollTriggerInstance, timeline) => {
  // kill animation when component unmounts/page change
  return () => {
    // if (!tl !== null) {
    timeline.kill(true);
    if (scrollTriggerInstance.getById(scrollTriggerId)) {
      // console.log(`KILLING SCROLLTRIGGER W/ ID: ${scrollTriggerId}`);
      scrollTriggerInstance.getById(scrollTriggerId).kill(true);
    }
    // }
  };
};

export default killScrollTrigger;

import { useEffect, useRef } from 'react';

// styles
import style from './index.module.scss';

// components
import VideoImageContainer from 'components/Other/VideoImageContainer';
import Arrow from 'components/Other/Arrow';

// assets
import video from 'assets/how-it-works-platform-channels.mp4';
import videoPoster from 'assets/placeholder-light-poster.jpg';

// packages
import gsap, { ScrollTrigger, Power3 } from 'gsap/all';

import startGsapAnimation from '../../../helpers/gsapMethods/startGsapAnimation';
import killScrollTrigger from '../../../helpers/gsapMethods/killScrollTrigger';
import generateId from '../../../helpers/generateId';

const platforms = [
  { name: 'DV360', asset: require('assets/platform-channels/dv360.svg').default },
  { name: 'Sizmek', asset: require('assets/platform-channels/sizmek.svg').default },
  { name: 'flashtalking', asset: require('assets/platform-channels/flashtalking.svg').default },
  { name: 'adform', asset: require('assets/platform-channels/adform.svg').default },
  { name: 'facebook', asset: require('assets/platform-channels/facebook.svg').default },
  { name: 'instagram', asset: require('assets/platform-channels/instagram.svg').default },
  { name: 'snapchat', asset: require('assets/platform-channels/snapchat.svg').default },
  { name: 'tiktok', asset: require('assets/platform-channels/tiktok.svg').default },
  { name: 'youtube', asset: require('assets/platform-channels/youtube.svg').default },
  { name: 'innovid', asset: require('assets/platform-channels/innovid.svg').default },
  { name: 'linkedin', asset: require('assets/platform-channels/linkedin.svg').default },
  { name: 'spotify', asset: require('assets/platform-channels/spotify.svg').default }
];

const PlatformChannels = () => {
  // register plugin
  gsap.registerPlugin(ScrollTrigger);

  // element refs
  const containerRef = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null),
    partnersRef = useRef(null),
    videoRef = useRef(null);

  let partnersRefs = [];

  const createIcons = platforms.map((platform) => (
    <div className={style['partner-logo']} key={platform.name} ref={(el) => partnersRefs.push(el)}>
      <img src={platform.asset} alt={platform.name} />
    </div>
  ));

  // timeline, null before init
  let tl = null;

  // animation
  const animate = (scrollTriggerId) => {
    tl = gsap.timeline({
      defaults: { id: scrollTriggerId, duration: 1, ease: Power3.ease },
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top 75%',
        end: 'top 25%',
        scrub: 2,
        once: true
        // onUpdate: (self) => {
        //   console.log(
        //     `progress ${scrollTriggerId}:, progress: ${self.progress}, start: ${
        //       self.start
        //     }, "velocity:", ${self.getVelocity()}, direction: ${self.direction}`
        //   );
        // }
      }
    });

    tl.to(titleRef.current, { autoAlpha: 1 }, 0);
    tl.to(textRef.current, { autoAlpha: 1 }, 0.5);
    tl.to(
      videoRef.current,
      {
        autoAlpha: 1,
        x: 0,
        onComplete: () => {
          if (videoRef.current !== null) {
            videoRef.current.childNodes[0].play();
          }
        }
      },
      0.75
    );

    tl.to(
      partnersRef.current.children,
      {
        autoAlpha: 1,
        stagger: {
          grid: [3, 4],
          from: 'left center',
          amount: 0.5,
          axis: 'y'
        }
      },
      0.5
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const scrollTriggerId = generateId();
      // console.log(`HOW IT WORKS > PLATFORMCHANNELS ID: ${scrollTriggerId}`);
      startGsapAnimation(scrollTriggerId, animate);
      return killScrollTrigger(scrollTriggerId, ScrollTrigger, tl);
    }, 750);
  }, []);

  return (
    <div className={style.section} ref={containerRef}>
      <div className={style.container}>
        <div className={style.left}>
          <div className={style['title-container']}>
            <div className={style.title} ref={titleRef}>
              Platform & channels
            </div>
            <div className={style.text} ref={textRef}>
              Our proprietary platform is intergrated across the digital media landscape, making DCO
              creative processes fast and easy.
            </div>
            <div className={style['partner-container']} ref={partnersRef}>
              {createIcons}
            </div>
          </div>
        </div>
        <div className={style.right}>
          <VideoImageContainer
            ref={videoRef}
            src={video}
            isVideo={true}
            showMuted={true}
            showFullscreen={true}
            muted={true}
            playsInline={true}
            posterImage={videoPoster}
            loop={true}
          />
        </div>
        <Arrow colour="#FFF" direction="down" classProps={style.arrow} />
      </div>
    </div>
  );
};

export default PlatformChannels;
